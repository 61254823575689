import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./ProductInfo.css";
import { useParams } from "react-router-dom";
import { message } from "antd";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useSelector } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import axios from "axios";

const ProductInfo = () => {
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const [property, setProperty] = useState(null);
  const [image, setImage] = useState(0);
  const [wishlist, setWishlist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wishLoader, setWishLoader] = useState(false);
  const [error, setError] = useState(false);
  const [err, setErr] = useState(false);
  const [form, setForm] = useState({
    bbId: "",
    name: "",
    email: "",
    mobile: "",
  });

  useEffect(() => {
    setForm((prevForm) => ({ ...prevForm, bbId: params?.bbId }));
  }, []);

  function handleChange(e) {
    const { value, name } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    if (name === "mobile") {
      if (isNaN(value)) {
        setErr(true);
      } else if (value.length > 10) {
        setErr(true);
      } else if (value.length < 10) {
        setErr(true);
      } else {
        setErr(false);
      }
    }
  }

  //! FORM SUBMIT
  async function handleSubmit(e) {
    e.preventDefault();
    if (form?.name === "" || form?.email === "" || form?.mobile === "") {
      return setError(true);
    }
    if (err) {
      return;
    }
    try {
      setLoading(true);
      const res = await axios.post("/api/interest/submit-interest", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "" });
        setLoading(false);
        setErr(false);
        setError(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  async function getProperty() {
    try {
      const res = await axios.post(
        "/api/property/get-property",
        {
          bbId: params?.bbId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProperty(res.data.data);
        checkWishlist();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //! ADD TO WISHLIST
  async function handleAddToWishlist() {
    if (!user) {
      message.error("Please Login First");
      return;
    }
    try {
      setWishLoader(true);
      const propertyObject = {
        email: user?.email,
        bbId: property.bbId,
        image: property?.images[0],
        title: property.title,
        about: property.about,
        price: property.price,
        bedroom: property.bedroom,
        type: property.type,
        category: property.category,
        area: property.area,
        builtupArea: property.builtupArea,
        plotArea: property.plotArea,
      };
      const res = await axios.post("/api/user/add-to-wishlist", propertyObject);
      if (res.data.success) {
        setWishlist(true);
        setWishLoader(false);
      } else {
        setWishLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setWishLoader(false);
      console.log(error);
    }
  }

  //! REMOVE FROM WISHLIST
  async function handleRemoveWishlist() {
    if (!user) {
      message.error("Please Login First");
      return;
    }
    try {
      setWishLoader(true);
      const propertyObject = {
        email: user?.email,
        bbId: property.bbId,
      };
      const res = await axios.post(
        "/api/user/remove-from-wishlist",
        propertyObject
      );
      if (res.data.success) {
        setWishlist(false);
        setWishLoader(false);
      } else {
        setWishLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setWishLoader(false);
    }
  }

  function checkWishlist() {
    if (user && user.wishlist && params?.bbId) {
      const isPresent = user.wishlist.some((item) => item.bbId === params.bbId);
      setWishlist(isPresent);
    } else {
      setWishlist(false);
    }
  }

  useEffect(() => {
    checkWishlist();
  }, [user]);

  useEffect(() => {
    getProperty();
  }, []);

  return (
    <Layout>
      <div className="product-info-container">
        <div className="product-info-container-left">
          <img
            className="main-img"
            // src={property && property?.images[image]}
            src={`https://bhiwandibroker.com/${property?.images[image]}`}
            alt=""
          />
          <div className="img-slider">
            {property?.images?.map((item, index) => {
              return (
                <img
                  key={index}
                  onClick={() => setImage(index)}
                  className={`${index === image && "active"}`}
                  src={`https://bhiwandibroker.com/${item}`}
                  alt="image"
                />
              );
            })}
          </div>
          <div className="product-about my-4">
            <h5>{property?.title}</h5>
            <hr />
            <h6>About</h6>
            <span>
              <small>{property?.about}</small>
            </span>
            {/* <hr />
            <h6>Address</h6>
            <span>
              <small>{property?.address}</small>
            </span> */}
          </div>
          {/* <hr /> */}
          {/* <div className="product-highlights">
            <h6>Highlights</h6>
            <span>
              <small>Air Conditioner</small>
            </span>
            <span>
              <small>Furnished</small>
            </span>
            <span>
              <small>Front Facing</small>
            </span>
            <span>
              <small>Water Supply</small>
            </span>
          </div> */}
          <hr />
          <h6>Details</h6>
          <div className="product-details">
            <div className="details">
              <span>
                <small>Type :</small>
              </span>
              <span>
                <small>
                  {property?.type === "Sell" ? "Sale" : property?.type}
                </small>
              </span>
            </div>
            <div className="details">
              <span>
                <small>Category :</small>
              </span>
              <span>
                <small>{property?.category}</small>
              </span>
            </div>
            {property?.category === "House" && (
              <div className="details">
                <span>
                  <small>Bedroom :</small>
                </span>
                <span>
                  <small>{property?.bedroom}</small>
                </span>
              </div>
            )}
            {property?.category === "House" && (
              <div className="details">
                <span>
                  <small>Bathrooms :</small>
                </span>
                <span>
                  <small>{property?.bathroom}</small>
                </span>
              </div>
            )}
            {(property?.category === "House" ||
              property?.category === "Shop/Office") && (
              <div className="details">
                <span>
                  <small>Furnishing :</small>
                </span>
                <span>
                  <small>{property?.furnishing}</small>
                </span>
              </div>
            )}
            {property?.category === "House" && (
              <div className="details">
                <span>
                  <small>Construction Status :</small>
                </span>
                <span>
                  <small>{property?.pStatus}</small>
                </span>
              </div>
            )}
            <div className="details">
              <span>
                <small>Posted By :</small>
              </span>
              <span>
                <small>{property?.postedBy}</small>
              </span>
            </div>
            {(property?.category === "House" ||
              property?.category === "Shop/Office") && (
              <div className="details">
                <span>
                  <small>Builtup Area :</small>
                </span>
                <span>
                  <small>{property?.builtupArea}</small>
                </span>
              </div>
            )}
            {(property?.category === "House" ||
              property?.category === "Shop/Office") && (
              <div className="details">
                <span>
                  <small>Carpet Area :</small>
                </span>
                <span>
                  <small>{property?.carpetArea}</small>
                </span>
              </div>
            )}
            {(property?.category === "House" ||
              property?.category === "Shop/Office") && (
              <div className="details">
                <span>
                  <small>Maintenance :</small>
                </span>
                <span>
                  <small>₹{property?.maintenanceCost}</small>
                </span>
              </div>
            )}
            {property?.category === "House" && (
              <div className="details">
                <span>
                  <small>Total Floors :</small>
                </span>
                <span>
                  <small>{property?.totalFloors}</small>
                </span>
              </div>
            )}
            {property?.category === "House" && (
              <div className="details">
                <span>
                  <small>Floor No. :</small>
                </span>
                <span>
                  <small>{property?.floorNumber}</small>
                </span>
              </div>
            )}
            {(property?.category === "House" ||
              property?.category === "Shop/Office") && (
              <div className="details">
                <span>
                  <small>Parking :</small>
                </span>
                <span>
                  <small>{property?.parking}</small>
                </span>
              </div>
            )}
            {property?.category === "Plot/Land" && (
              <div className="details">
                <span>
                  <small>Plot Area :</small>
                </span>
                <span>
                  <small>
                    {property?.plotArea} {property?.plotAreaUnit}
                  </small>
                </span>
              </div>
            )}
            {property?.category === "Plot/Land" && (
              <div className="details">
                <span>
                  <small>Plot Length :</small>
                </span>
                <span>
                  <small>
                    {property?.plotLength} {property?.plotAreaUnit}
                  </small>
                </span>
              </div>
            )}
            {property?.category === "Plot/Land" && (
              <div className="details">
                <span>
                  <small>Plot Breadth :</small>
                </span>
                <span>
                  <small>
                    {property?.plotBreadth} {property?.plotAreaUnit}
                  </small>
                </span>
              </div>
            )}
            {property?.category === "Plot/Land" && (
              <div className="details">
                <span>
                  <small>Plot Open Sides :</small>
                </span>
                <span>
                  <small>{property?.plotOpenSides}</small>
                </span>
              </div>
            )}
            {property?.category === "Plot/Land" && (
              <div className="details">
                <span>
                  <small>Any Construction :</small>
                </span>
                <span>
                  <small>{property?.plotConsDone}</small>
                </span>
              </div>
            )}
            {property?.category === "Shop/Office" && (
              <div className="details">
                <span>
                  <small>Washroom :</small>
                </span>
                <span>
                  <small>{property?.washroom}</small>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="product-info-container-right form-sticky">
          <div className="product-price">
            <h4>
              <b>
                ₹{property?.price} {property?.type === "Rent" && "Monthly"}
              </b>
            </h4>
            <div>
              {/* <ShareIcon /> */}
              {wishlist ? (
                wishLoader ? (
                  <div class="spinner-grow text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <FavoriteIcon
                    onClick={handleRemoveWishlist}
                    className="icon text-danger"
                  />
                )
              ) : wishLoader ? (
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <FavoriteBorderIcon
                  className="icon"
                  onClick={handleAddToWishlist}
                />
              )}
            </div>
          </div>
          <div className="more-details">
            {property?.category === "House" ? (
              <h6>
                {property?.bedroom}BHK - {property?.bathroom}BA -{" "}
                {property?.builtupArea} sqft
              </h6>
            ) : property?.category === "Shop/Office" ? (
              <h6>{property?.builtupArea} sqft</h6>
            ) : property?.category === "Plot/Land" ? (
              <h6>{property?.plotArea} sqft</h6>
            ) : (
              ""
            )}
            <span className="text-muted d-flex justify-content-between">
              <small>{property?.area}</small>
              <small>
                {new Date(property?.created).toLocaleString("default", {
                  day: "numeric",
                  month: "long",
                })}
              </small>
            </span>
          </div>
          <div className="product-contact">
            <h5>Contact Us</h5>
            <div className="form-fields mb-2">
              <input
                onChange={handleChange}
                value={form?.name}
                type="text"
                className="py-2 w-100"
                placeholder="Name"
                name="name"
                classNamem-0
              />
              <small className="text-danger">
                {error && form?.name === "" && "Enter name"}
              </small>
            </div>
            <div className="form-fields mb-2">
              <input
                onChange={handleChange}
                value={form?.email}
                type="text"
                className="py-2 w-100"
                placeholder="Email"
                name="email"
              />
              <small className="text-danger">
                {error && form?.email === "" && "Enter email"}
              </small>
            </div>
            <div className="form-fields mb-2">
              <input
                onChange={handleChange}
                value={form?.mobile}
                type="text"
                className="py-2 w-100"
                placeholder="Mobile"
                name="mobile"
              />
              <small className="text-danger">
                {error && form?.mobile === ""
                  ? "Enter mobile"
                  : err && isNaN(form?.mobile)
                  ? "Enter only numbers"
                  : err && form?.mobile.length > 10
                  ? "Enter only 10 digits "
                  : err && form?.mobile.length < 10
                  ? "Enter 10 digits"
                  : ""}
              </small>
            </div>
            <button onClick={handleSubmit} className="bb-btn">
              {loading ? "Submiting" : "Submit"}
              {loading && (
                <div class="ms-2 spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductInfo;
