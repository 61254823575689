import React, { useEffect, useState } from "react";
import "./MyListings.css";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";
import NoResultFound from "../components/UI/NoResultFound";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";

const MyWishlist = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">My WISHLIST</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div className="my-listings-container">
            <div className="my-listed-product-container">
              {user && user.wishlist && user.wishlist.length > 0 ? (
                user?.wishlist.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="my-listed-product"
                      onClick={() => navigate(`/property-info/${item?.bbId}`)}
                    >
                      <div className="my-listed-product-img">
                        {/* <span className="camera-tag">
                          {item?.images?.length}
                          <CameraAltIcon className="icon ms-1" />
                        </span> */}
                        <img src={item?.image} alt="" />
                      </div>
                      <div className="my-listed-product-content">
                        <div className="my-listing-name">
                          <h6>{item?.title}</h6>
                          {item?.category === "House" ? (
                            <p className="m-0">
                              {item?.bedroom} BHK on{" "}
                              {item?.type === "Rent" ? "Rent" : "Sale"} in{" "}
                              {item?.area}
                            </p>
                          ) : item?.category === "Plot/Land" ? (
                            <p className="m-0">
                              {item?.plotArea} {item?.plotAreaUnit} Plot on{" "}
                              {item?.type === "Rent" ? "Rent" : "Sale"} in{" "}
                              {item?.area}
                            </p>
                          ) : item?.category === "Shop/Office" ? (
                            <p className="m-0">
                              Shop on {item?.type === "Rent" ? "Rent" : "Sale"}{" "}
                              in {item?.area}
                            </p>
                          ) : (
                            ""
                          )}
                          <span>
                            <small>{item?.about}</small>
                          </span>
                          <h6 className="mt-2 mb-0">Price: {item?.price}</h6>
                        </div>
                        <div className="my-listing-action">
                          <div className="listing-delete">
                            <FavoriteIcon className="icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoResultFound />
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default MyWishlist;
