import React from "react";
import Header from "../components/Header/Header";
import "./About.css";
import WeAre from "../components/About/WeAre";
import Text from "../components/About/Text";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import HowItWorks from "../components/Home/HowItWorks";

const About = () => {
  const about = [
    {
      h4: "Connecting Dreams to Spaces",
      p: "Welcome to Bhiwandi Broker, where we believe in turning aspirations into addresses. Our platform is more than just a space for property listings; it's a vibrant community dedicated to simplifying the way people find, connect, and realize their dream spaces.",
    },
    {
      h4: "Our Mission: Empowering Property Dreams",
      p: "At Bhiwandi Broker, our mission is to empower individuals and businesses to discover their perfect spaces effortlessly. We understand that finding the right property is not just a transaction; it's a journey towards creating a home, establishing a business, or securing an investment for the future. Our platform strives to make this journey seamless, enjoyable, and ultimately, successful.",
    },
    {
      h4: "Our Vision: Redefining Real Estate Discovery",
      p: "We envision a future where everyone has the tools and resources to explore and secure their ideal properties. Through cutting-edge technology and a user-centric approach, we aim to redefine the real estate discovery experience. Whether you're looking for a cozy home, a strategic business location, or a lucrative investment opportunity, Bhiwandi Broker is here to make your vision a reality.",
    },
  ];

  const WhyMobiHeaven = () => {
    return (
      <div className="container why-choose-bhiwandi-broker my-5">
        <h2 className="yline text-center w-100">
          <b>Why Choose Us?</b>
        </h2>
        <div className="bhiwandi-broker text-center">
          <h4>User-Friendly Experience</h4>
          <p>
            We prioritize your satisfaction, offering a seamless and
            user-friendly platform. Navigate listings, express interest, and
            connect effortlessly with property owners. Our commitment ensures a
            hassle-free and enjoyable property search experience.
          </p>
        </div>
        <div className="bhiwandi-broker text-center">
          <h4>Transparent Transactions and Trust</h4>
          <p>
            Trust is fundamental at Bhiwandi Broker. We prioritize transparency,
            offering detailed property information for a clear understanding.
            Our secure transactions and reliable support reinforce trust,
            ensuring peace of mind on your property journey.
          </p>
        </div>
        <div className="bhiwandi-broker text-center">
          <h4>Personalized Property Matches</h4>
          <p>
            Bhiwandi Broker caters to your unique property needs. Our advanced
            algorithms analyze your preferences, delivering personalized matches
            tailored just for you. Experience curated selections aligned with
            your vision, not generic searches.
          </p>
        </div>
        <div className="bhiwandi-broker text-center">
          <h4>Responsive Customer Support</h4>
          <p>
            Your satisfaction is paramount. Our dedicated customer support team
            is ready to assist you at every step of your property journey. Reach
            out for prompt and responsive assistance—your peace of mind is our
            commitment.
          </p>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="container about-container">
        {about.map((item, index) => {
          return (
            <div key={index} className="mb-4">
              <h4>
                <b>{item.h4}</b>
              </h4>
              <p>{item.p}</p>
            </div>
          );
        })}
      </div>
      <HowItWorks />
      <WhyMobiHeaven />
    </Layout>
  );
};

export default About;
