import React from "react";
import Slider from "react-slick";
import "./Agents.css";
import IMAGES from "../img/image";

const Agents = () => {
  const agents = [
    {
      name: "Anna Lipa",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab natus debitis similique consequuntur! Ab, dolorum laboriosam. Voluptas quidem ullam qui.",
      img: `${IMAGES.agent1}`,
    },
    {
      name: "Jane Kobert",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab natus debitis similique consequuntur! Ab, dolorum laboriosam. Voluptas quidem ullam qui.",
      img: `${IMAGES.agent2}`,
    },
    {
      name: "Bill Trust",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab natus debitis similique consequuntur! Ab, dolorum laboriosam. Voluptas quidem ullam qui.",
      img: `${IMAGES.agent3}`,
    },
    {
      name: "Martin Smith",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab natus debitis similique consequuntur! Ab, dolorum laboriosam. Voluptas quidem ullam qui.",
      img: `${IMAGES.agent4}`,
    },
  ];

  var settings = {
    dots: true,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="agents-container">
      <span className="text-center d-block m-auto">
        <span className="text-green">
          <small>
            <b>THE BEST AGENTS</b>
          </small>
        </span>
        <h4>Meet Our Agents</h4>
      </span>
      <div className="agents-slider">
        <Slider {...settings}>
          {agents?.map((agent, index) => {
            return (
              <div className="agent">
                <div className="agent-img">
                  <img src={agent.img} alt="" />
                </div>
                <div className="agent-content">
                  <h6>{agent.name}</h6>
                  <span>
                    <small>{agent.desc}</small>
                  </span>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Agents;
