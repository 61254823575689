import React from "react";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminSidemenu from "./AdminSidemenu";

const AdminLayout = ({ children }) => {
  return (
    <div className="admin-layout-container">
      <AdminHeader />
      <div className="admin-body">
        <div className="admin-sidebar d-none d-md-none d-lg-block">
          <AdminSidebar />
        </div>
        <div className="admin-body-content">{children}</div>
      </div>
      <footer>
        <div className="admin-footer">
          <span>ADMIN</span>
          <span>~aashirdigital</span>
        </div>
      </footer>
    </div>
  );
};

export default AdminLayout;
