const bhiwandiAreas = {
  data: [
    {
      id: 5326640,
      name: "Ajay Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.0564,
      latitude: 19.3069,
      parentId: 4058965,
    },
    {
      id: 5326641,
      name: "Alimghar",
      type: "NEIGHBOURHOOD",
      longitude: 73.03578,
      latitude: 19.20509,
      parentId: 4058965,
    },
    {
      id: 5326642,
      name: "Alkhivali",
      type: "NEIGHBOURHOOD",
      longitude: 73.04741,
      latitude: 19.31847,
      parentId: 4058965,
    },
    {
      id: 5326643,
      name: "Anjur",
      type: "NEIGHBOURHOOD",
      longitude: 73.03863,
      latitude: 19.21313,
      parentId: 4058965,
    },
    {
      id: 5326644,
      name: "Anjurphata",
      type: "NEIGHBOURHOOD",
      longitude: 73.03604,
      latitude: 19.27547,
      parentId: 4058965,
    },
    {
      id: 5326645,
      name: "Ansari Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.07359,
      latitude: 19.29933,
      parentId: 4058965,
    },
    {
      id: 5326646,
      name: "Ashok Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.0679,
      latitude: 19.29378,
      parentId: 4058965,
    },
    {
      id: 5326647,
      name: "Avachit Pada",
      type: "NEIGHBOURHOOD",
      longitude: 73.0687,
      latitude: 19.30993,
      parentId: 4058965,
    },
    {
      id: 5326648,
      name: "Balaji Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.04737,
      latitude: 19.28513,
      parentId: 4058965,
    },
    {
      id: 5460006,
      name: "Bhadane",
      type: "NEIGHBOURHOOD",
      longitude: 73.181408,
      latitude: 19.349028,
      parentId: 4058965,
    },
    {
      id: 5326649,
      name: "Bhadwad Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.08277,
      latitude: 19.28654,
      parentId: 4058965,
    },
    {
      id: 5326650,
      name: "Bhagwan Shri Sheetalnath Dada Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05342,
      latitude: 19.29083,
      parentId: 4058965,
    },
    {
      id: 5326651,
      name: "Bharodi",
      type: "NEIGHBOURHOOD",
      longitude: 73.05179,
      latitude: 19.21244,
      parentId: 4058965,
    },
    {
      id: 5326652,
      name: "Bhaskar Mali Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05488,
      latitude: 19.29641,
      parentId: 4058965,
    },
    {
      id: 5326653,
      name: "Bhgwan Mahavir Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.04286,
      latitude: 19.27208,
      parentId: 4058965,
    },
    {
      id: 5326654,
      name: "Bhinar",
      type: "NEIGHBOURHOOD",
      longitude: 73.089,
      latitude: 19.32572,
      parentId: 4058965,
    },
    {
      id: 5373169,
      name: "Bhiwandi Nizampur",
      type: "NEIGHBOURHOOD",
      longitude: 73.05241,
      latitude: 19.27494,
      parentId: 4058965,
    },
    {
      id: 5326655,
      name: "Chavindra Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.07642,
      latitude: 19.31675,
      parentId: 4058965,
    },
    {
      id: 5326656,
      name: "Dahyale",
      type: "NEIGHBOURHOOD",
      longitude: 73.041862,
      latitude: 19.320868,
      parentId: 4058965,
    },
    {
      id: 5373171,
      name: "Dapoda Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.03912,
      latitude: 19.24557,
      parentId: 4058965,
    },
    {
      id: 5326657,
      name: "Dapode",
      type: "NEIGHBOURHOOD",
      longitude: 73.0423,
      latitude: 19.23679,
      parentId: 4058965,
    },
    {
      id: 5373173,
      name: "Dhamankar Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.05466,
      latitude: 19.28856,
      parentId: 4058965,
    },
    {
      id: 5326658,
      name: "Dindi Gada",
      type: "NEIGHBOURHOOD",
      longitude: 73.09625,
      latitude: 19.30098,
      parentId: 4058965,
    },
    {
      id: 5326659,
      name: "Dive Anjur",
      type: "NEIGHBOURHOOD",
      longitude: 73.0315,
      latitude: 19.22995,
      parentId: 4058965,
    },
    {
      id: 5326660,
      name: "Dunge",
      type: "NEIGHBOURHOOD",
      longitude: 73.0146996,
      latitude: 19.2900684,
      parentId: 4058965,
    },
    {
      id: 5326661,
      name: "Farid Abdul Kadir Narvel Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05264,
      latitude: 19.30036,
      parentId: 4058965,
    },
    {
      id: 5326662,
      name: "Gaibi Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.07612,
      latitude: 19.2901,
      parentId: 4058965,
    },
    {
      id: 5326663,
      name: "Ganesh Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.05658,
      latitude: 19.2743,
      parentId: 4058965,
    },
    {
      id: 5326664,
      name: "Ganpat Bajage Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05535,
      latitude: 19.27182,
      parentId: 4058965,
    },
    {
      id: 5326665,
      name: "Gayatri Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.08359,
      latitude: 19.29611,
      parentId: 4058965,
    },
    {
      id: 5326666,
      name: "Geeta Mandir Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.057089,
      latitude: 19.288974,
      parentId: 4058965,
    },
    {
      id: 5373175,
      name: "Gholgaon",
      type: "NEIGHBOURHOOD",
      longitude: 73.08948,
      latitude: 19.28692,
      parentId: 4058965,
    },
    {
      id: 5326667,
      name: "Gopal Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.06426,
      latitude: 19.29648,
      parentId: 4058965,
    },
    {
      id: 5326668,
      name: "Gorsai",
      type: "NEIGHBOURHOOD",
      longitude: 73.0803713,
      latitude: 19.333005,
      parentId: 4058965,
    },
    {
      id: 5326669,
      name: "Gove",
      type: "NEIGHBOURHOOD",
      longitude: 73.07804,
      latitude: 19.24515,
      parentId: 4058965,
    },
    {
      id: 5326670,
      name: "Gundavali",
      type: "NEIGHBOURHOOD",
      longitude: 73.04079,
      latitude: 19.22843,
      parentId: 4058965,
    },
    {
      id: 5373177,
      name: "Gundavali Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.03398,
      latitude: 19.24623,
      parentId: 4058965,
    },
    {
      id: 5326671,
      name: "Gundi Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.03385,
      latitude: 19.25303,
      parentId: 4058965,
    },
    {
      id: 5326672,
      name: "Haji Baba Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.06076,
      latitude: 19.3062,
      parentId: 4058965,
    },
    {
      id: 5326673,
      name: "Hajmal Khwaja Garib Nawaj Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.08114,
      latitude: 19.29311,
      parentId: 4058965,
    },
    {
      id: 5326674,
      name: "Hari Om Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.04994,
      latitude: 19.31572,
      parentId: 4058965,
    },
    {
      id: 5326675,
      name: "Heera Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 72.947157,
      latitude: 19.160991,
      parentId: 4058965,
    },
    {
      id: 5326676,
      name: "Kalher Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.01862,
      latitude: 19.24611,
      parentId: 4058965,
    },
    {
      id: 5326677,
      name: "Kalwar",
      type: "NEIGHBOURHOOD",
      longitude: 73.02316,
      latitude: 19.25929,
      parentId: 4058965,
    },
    {
      id: 5373179,
      name: "Kalyan Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.06293,
      latitude: 19.29651,
      parentId: 4058965,
    },
    {
      id: 5373181,
      name: "Kalyan Phata",
      type: "NEIGHBOURHOOD",
      longitude: 73.08342,
      latitude: 19.26937,
      parentId: 4058965,
    },
    {
      id: 5460034,
      name: "Kalyan West",
      type: "NEIGHBOURHOOD",
      longitude: 73.12172,
      latitude: 19.24413,
      parentId: 4058965,
    },
    {
      id: 5326678,
      name: "Kamatghar",
      type: "NEIGHBOURHOOD",
      longitude: 73.05526,
      latitude: 19.27465,
      parentId: 4058965,
    },
    {
      id: 5326679,
      name: "Kambe",
      type: "NEIGHBOURHOOD",
      longitude: 73.03648,
      latitude: 19.31213,
      parentId: 4058965,
    },
    {
      id: 5326680,
      name: "Kaneri",
      type: "NEIGHBOURHOOD",
      longitude: 73.05857,
      latitude: 19.29573,
      parentId: 4058965,
    },
    {
      id: 5326681,
      name: "Karivali",
      type: "NEIGHBOURHOOD",
      longitude: 73.03823,
      latitude: 19.29732,
      parentId: 4058965,
    },
    {
      id: 5326682,
      name: "Kasheli Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.01371,
      latitude: 19.23733,
      parentId: 4058965,
    },
    {
      id: 5373183,
      name: "Katai Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.04748,
      latitude: 19.30889,
      parentId: 4058965,
    },
    {
      id: 5326683,
      name: "Katai Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.04416,
      latitude: 19.30608,
      parentId: 4058965,
    },
    {
      id: 5326684,
      name: "Kawad Kh",
      type: "NEIGHBOURHOOD",
      longitude: 73.07792,
      latitude: 19.3225,
      parentId: 4058965,
    },
    {
      id: 5326685,
      name: "Kewani",
      type: "NEIGHBOURHOOD",
      longitude: 73.0125,
      latitude: 19.26181,
      parentId: 4058965,
    },
    {
      id: 5326686,
      name: "Khandepada",
      type: "NEIGHBOURHOOD",
      longitude: 73.06513,
      latitude: 19.30555,
      parentId: 4058965,
    },
    {
      id: 5326687,
      name: "Khawja Garib Navaz Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.08081,
      latitude: 19.293173,
      parentId: 4058965,
    },
    {
      id: 5326688,
      name: "Khoni Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.05469,
      latitude: 19.31544,
      parentId: 4058965,
    },
    {
      id: 5326689,
      name: "Khudad Bardi Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.06372,
      latitude: 19.30289,
      parentId: 4058965,
    },
    {
      id: 5326690,
      name: "Kolivali",
      type: "NEIGHBOURHOOD",
      longitude: 73.05831,
      latitude: 19.32117,
      parentId: 4058965,
    },
    {
      id: 5326691,
      name: "Kon",
      type: "NEIGHBOURHOOD",
      longitude: 73.10591,
      latitude: 19.2476,
      parentId: 4058965,
    },
    {
      id: 5326692,
      name: "Kopar",
      type: "NEIGHBOURHOOD",
      longitude: 73.0207,
      latitude: 19.25323,
      parentId: 4058965,
    },
    {
      id: 5326693,
      name: "Late Balu Narayan Patil Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.04157,
      latitude: 19.28096,
      parentId: 4058965,
    },
    {
      id: 5326694,
      name: "Late P Keroba Chaphekar Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05252,
      latitude: 19.29924,
      parentId: 4058965,
    },
    {
      id: 5326695,
      name: "Late Raghu Rathod Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.08324,
      latitude: 19.28332,
      parentId: 4058965,
    },
    {
      id: 5326696,
      name: "Late S Bai Shaniwar Joshi Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.0796,
      latitude: 19.29092,
      parentId: 4058965,
    },
    {
      id: 5326697,
      name: "Late Shankarao Kolekar Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05938,
      latitude: 19.30886,
      parentId: 4058965,
    },
    {
      id: 5326698,
      name: "Mahada Colony",
      type: "NEIGHBOURHOOD",
      longitude: 73.06329,
      latitude: 19.3123,
      parentId: 4058965,
    },
    {
      id: 5326699,
      name: "Mankoli Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.04231,
      latitude: 19.23988,
      parentId: 4058965,
    },
    {
      id: 5326700,
      name: "Mhatre Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.082241,
      latitude: 19.210562,
      parentId: 4058965,
    },
    {
      id: 5326701,
      name: "Millat Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.05434,
      latitude: 19.28576,
      parentId: 4058965,
    },
    {
      id: 5326702,
      name: "Mithpada",
      type: "NEIGHBOURHOOD",
      longitude: 73.065,
      latitude: 19.31568,
      parentId: 4058965,
    },
    {
      id: 5326703,
      name: "Naigaon",
      type: "NEIGHBOURHOOD",
      longitude: 73.07964,
      latitude: 19.30903,
      parentId: 4058965,
    },
    {
      id: 5326704,
      name: "Narpoli",
      type: "NEIGHBOURHOOD",
      longitude: 73.052,
      latitude: 19.29307,
      parentId: 4058965,
    },
    {
      id: 5326705,
      name: "Narpoli Gaon",
      type: "NEIGHBOURHOOD",
      longitude: 73.04273,
      latitude: 19.27877,
      parentId: 4058965,
    },
    {
      id: 5326706,
      name: "Nazaruddin Bobade Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05578,
      latitude: 19.29753,
      parentId: 4058965,
    },
    {
      id: 5326707,
      name: "Nizampura",
      type: "NEIGHBOURHOOD",
      longitude: 73.06389,
      latitude: 19.30487,
      parentId: 4058965,
    },
    {
      id: 5326708,
      name: "Ovali",
      type: "NEIGHBOURHOOD",
      longitude: 73.05409,
      latitude: 19.25738,
      parentId: 4058965,
    },
    {
      id: 5326709,
      name: "Paar Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.05698,
      latitude: 19.30152,
      parentId: 4058965,
    },
    {
      id: 1000000000003425,
      name: "Padgha",
      type: "NEIGHBOURHOOD",
      longitude: 73.1742189,
      latitude: 19.3642416,
      parentId: 4058965,
    },
    {
      id: 5326710,
      name: "Padma Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.06887,
      latitude: 19.28062,
      parentId: 4058965,
    },
    {
      id: 5326711,
      name: "Pai Mehrunnisa Usuf Momin Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05912,
      latitude: 19.29907,
      parentId: 4058965,
    },
    {
      id: 5326712,
      name: "Pimpalas",
      type: "NEIGHBOURHOOD",
      longitude: 73.07529,
      latitude: 19.25191,
      parentId: 4058965,
    },
    {
      id: 5373185,
      name: "Pimpalgaon",
      type: "NEIGHBOURHOOD",
      longitude: 73.10308,
      latitude: 19.24901,
      parentId: 4058965,
    },
    {
      id: 5373187,
      name: "Pimpalghar",
      type: "NEIGHBOURHOOD",
      longitude: 73.09057,
      latitude: 19.25422,
      parentId: 4058965,
    },
    {
      id: 5373189,
      name: "Pimpalner",
      type: "NEIGHBOURHOOD",
      longitude: 73.06851,
      latitude: 19.2467,
      parentId: 4058965,
    },
    {
      id: 5326713,
      name: "Pipeline Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.07496,
      latitude: 19.28174,
      parentId: 4058965,
    },
    {
      id: 5460061,
      name: "Prabhu Ali",
      type: "NEIGHBOURHOOD",
      longitude: 73.056377,
      latitude: 19.300359,
      parentId: 4058965,
    },
    {
      id: 5326714,
      name: "Purne",
      type: "NEIGHBOURHOOD",
      longitude: 73.02669,
      latitude: 19.25725,
      parentId: 4058965,
    },
    {
      id: 5326715,
      name: "Rahanal",
      type: "NEIGHBOURHOOD",
      longitude: 73.09381,
      latitude: 19.26286,
      parentId: 4058965,
    },
    {
      id: 5326716,
      name: "Ranjnoli",
      type: "NEIGHBOURHOOD",
      longitude: 73.08466,
      latitude: 19.26794,
      parentId: 4058965,
    },
    {
      id: 5326717,
      name: "Sai Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.06798,
      latitude: 19.29539,
      parentId: 4058965,
    },
    {
      id: 5373191,
      name: "Sai Nagar Bhadavad",
      type: "NEIGHBOURHOOD",
      longitude: 73.08487,
      latitude: 19.28882,
      parentId: 4058965,
    },
    {
      id: 5326718,
      name: "Salauddinan Azmi Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05187,
      latitude: 19.30487,
      parentId: 4058965,
    },
    {
      id: 5326719,
      name: "Samarth Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.06105,
      latitude: 19.29379,
      parentId: 4058965,
    },
    {
      id: 5326720,
      name: "Sangampada",
      type: "NEIGHBOURHOOD",
      longitude: 73.05989,
      latitude: 19.30949,
      parentId: 4058965,
    },
    {
      id: 5326721,
      name: "Sant S Rohidas Maharaj Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05054,
      latitude: 19.28375,
      parentId: 4058965,
    },
    {
      id: 5373193,
      name: "Sarang",
      type: "NEIGHBOURHOOD",
      longitude: 73.05671,
      latitude: 19.22595,
      parentId: 4058965,
    },
    {
      id: 5326722,
      name: "Saravali",
      type: "NEIGHBOURHOOD",
      longitude: 73.0965,
      latitude: 19.26591,
      parentId: 4058965,
    },
    {
      id: 5373195,
      name: "Saravali MIDC",
      type: "NEIGHBOURHOOD",
      longitude: 73.09776,
      latitude: 19.26164,
      parentId: 4058965,
    },
    {
      id: 5326723,
      name: "Saudagar Mohalla",
      type: "NEIGHBOURHOOD",
      longitude: 73.05213,
      latitude: 19.30122,
      parentId: 4058965,
    },
    {
      id: 5326724,
      name: "Savre Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.0587,
      latitude: 19.2898,
      parentId: 4058965,
    },
    {
      id: 5326725,
      name: "Sawandhe",
      type: "NEIGHBOURHOOD",
      longitude: 73.08098,
      latitude: 19.31893,
      parentId: 4058965,
    },
    {
      id: 5408231,
      name: "Shahapur",
      type: "NEIGHBOURHOOD",
      longitude: 73.329602,
      latitude: 19.455925,
      parentId: 4058965,
    },
    {
      id: 5326727,
      name: "Shastri Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.06915,
      latitude: 19.28649,
      parentId: 4058965,
    },
    {
      id: 5326728,
      name: "Shelar",
      type: "NEIGHBOURHOOD",
      longitude: 73.06764,
      latitude: 19.32091,
      parentId: 4058965,
    },
    {
      id: 5326729,
      name: "Shri Sampadabai Sitaram Naik Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.08148,
      latitude: 19.28234,
      parentId: 4058965,
    },
    {
      id: 5326730,
      name: "Sir Sayad Ahemad Khan Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.07191,
      latitude: 19.28534,
      parentId: 4058965,
    },
    {
      id: 5373197,
      name: "Sonale",
      type: "NEIGHBOURHOOD",
      longitude: 73.0935198,
      latitude: 19.2865583,
      parentId: 4058965,
    },
    {
      id: 5326731,
      name: "Surai",
      type: "NEIGHBOURHOOD",
      longitude: 73.0591,
      latitude: 19.22334,
      parentId: 4058965,
    },
    {
      id: 5326732,
      name: "Tadali",
      type: "NEIGHBOURHOOD",
      longitude: 73.05539,
      latitude: 19.27131,
      parentId: 4058965,
    },
    {
      id: 5326733,
      name: "Temghar Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.0738,
      latitude: 19.28208,
      parentId: 4058965,
    },
    {
      id: 5373199,
      name: "Thakurgaon",
      type: "NEIGHBOURHOOD",
      longitude: 73.09077,
      latitude: 19.26694,
      parentId: 4058965,
    },
    {
      id: 5326734,
      name: "Thakurpada Saravali",
      type: "NEIGHBOURHOOD",
      longitude: 73.08759,
      latitude: 19.27065,
      parentId: 4058965,
    },
    {
      id: 5326735,
      name: "Themgharpada",
      type: "NEIGHBOURHOOD",
      longitude: 73.08256,
      latitude: 19.28208,
      parentId: 4058965,
    },
    {
      id: 5326736,
      name: "Tilak Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.1264779,
      latitude: 19.2434757,
      parentId: 4058965,
    },
    {
      id: 1000000000003423,
      name: "Umbarmali",
      type: "NEIGHBOURHOOD",
      longitude: 73.3897992,
      latitude: 19.6512763,
      parentId: 4058965,
    },
    {
      id: 5326737,
      name: "Vaghivali",
      type: "NEIGHBOURHOOD",
      longitude: 73.05985,
      latitude: 19.31671,
      parentId: 4058965,
    },
    {
      id: 5326738,
      name: "Vaidya Wada",
      type: "NEIGHBOURHOOD",
      longitude: 73.05617,
      latitude: 19.30041,
      parentId: 4058965,
    },
    {
      id: 5326739,
      name: "Val Village",
      type: "NEIGHBOURHOOD",
      longitude: 73.03802,
      latitude: 19.25724,
      parentId: 4058965,
    },
    {
      id: 5326740,
      name: "Vaman Dev Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05681,
      latitude: 19.29448,
      parentId: 4058965,
    },
    {
      id: 5373201,
      name: "Vanjar Patti Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.06473,
      latitude: 19.30795,
      parentId: 4058965,
    },
    {
      id: 1000000000003424,
      name: "Vasind",
      type: "NEIGHBOURHOOD",
      longitude: 73.2543469,
      latitude: 19.4051636,
      parentId: 4058965,
    },
    {
      id: 5326741,
      name: "Vehele",
      type: "NEIGHBOURHOOD",
      longitude: 73.06211,
      latitude: 19.23632,
      parentId: 4058965,
    },
    {
      id: 5326742,
      name: "Vijay Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.1386485,
      latitude: 19.2226789,
      parentId: 4058965,
    },
    {
      id: 5326743,
      name: "Vitthal Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.05012,
      latitude: 19.28544,
      parentId: 4058965,
    },
    {
      id: 5326744,
      name: "Yesubai Nagar",
      type: "NEIGHBOURHOOD",
      longitude: 73.048,
      latitude: 19.30792,
      parentId: 4058965,
    },
    {
      id: 5373203,
      name: "Zakaat Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.06434,
      latitude: 19.29837,
      parentId: 4058965,
    },
    {
      id: 5326745,
      name: "Zenda Naka",
      type: "NEIGHBOURHOOD",
      longitude: 73.05531,
      latitude: 19.30148,
      parentId: 4058965,
    },
    {
      id: 5326746,
      name: "Zzumberlal Kalantri Chowk",
      type: "NEIGHBOURHOOD",
      longitude: 73.05646,
      latitude: 19.30058,
      parentId: 4058965,
    },
  ],
  metadata: {
    totalMatches: 131,
    remainingMatches: 0,
  },
  empty: false,
};

export default bhiwandiAreas;
