import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import { Link, useNavigate } from "react-router-dom";
import IMAGES from "../../img/image";
import { message } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="home">
      <div className="home-left">
        <h1>
          Find your sweet <br />
          home easily
        </h1>
        <p>
          Discover comfort and style in this charming sanctuary, blending modern
          aesthetics with timeless charm. Your sweet home awaits serenely.
        </p>
        <button className="bb-btn" onClick={() => navigate("/properties")}>
          <TravelExploreIcon className="icon me-2" />
          Search Homes
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
