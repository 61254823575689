import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="bg-heading">
        <h4>
          <b>Privacy Policy</b>
        </h4>
      </div>
      <div className="terms-container">
        <h1>Privacy Policy</h1>
        <p>Last updated: January 30, 2024</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <h4 className="mt-5">Interpretation and Definitions</h4>
        <h5>Interpretation</h5>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h5>Definitions</h5>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              &quot;control&quot; means ownership of 50% or more of the shares,
              equity interest or other securities entitled to vote for election
              of directors or other managing authority.
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to Bhiwandi Broker.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: Maharashtra, India
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
          </li>
          <li>
            <p>
              <strong>Website</strong> refers to Bhiwandi Broker, accessible
              from{" "}
              <a
                href="https://bhiwandibroker.com"
                rel="external nofollow noopener"
                target="_blank"
              >
                https://bhiwandibroker.com
              </a>
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
        <h4 className="mt-5">Collecting and Using Your Personal Data</h4>
        <h5>Types of Data Collected</h5>
        <h4>Personal Data</h4>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>
            <p>Email address</p>
          </li>
          <li>
            <p>First name and last name</p>
          </li>
          <li>
            <p>Phone number</p>
          </li>
          <li>
            <p>Address, State, Province, ZIP/Postal code, City</p>
          </li>
          <li>
            <p>Usage Data</p>
          </li>
        </ul>
        <h4>Usage Data</h4>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <h4>Tracking Technologies and Cookies</h4>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small
            file placed on Your Device. You can instruct Your browser to refuse
            all Cookies or to indicate when a Cookie is being sent. However, if
            You do not accept Cookies, You may not be able to use some parts of
            our Service. Unless you have adjusted Your browser setting so that
            it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <strong>Web Beacons.</strong> Certain sections of our Service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>
        <p>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on Your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close Your web browser. You can learn more about cookies on{" "}
          <a
            href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
            target="_blank"
          >
            TermsFeed website
          </a>{" "}
          article.
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>
        <ul>
          <li>
            <p>
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </li>
        </ul>
        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
        <h5>Use of Your Personal Data</h5>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>
            <p>
              <strong>To provide and maintain our Service</strong>, including to
              monitor the usage of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>To manage Your Account:</strong> to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </p>
          </li>
          <li>
            <p>
              <strong>For the performance of a contract:</strong> the
              development, compliance and undertaking of the purchase contract
              for the products, items or services You have purchased or of any
              other contract with Us through the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </p>
          </li>
          <li>
            <p>
              <strong>To provide You</strong> with news, special offers and
              general information about other goods, services and events which
              we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such
              information.
            </p>
          </li>
          <li>
            <p>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </p>
          </li>
          <li>
            <p>
              <strong>For business transfers:</strong> We may use Your
              information to evaluate or conduct a merger, divestiture,
              restructuring, reorganization, dissolution, or other sale or
              transfer of some or all of Our assets, whether as a going concern
              or as part of bankruptcy, liquidation, or similar proceeding, in
              which Personal Data held by Us about our Service users is among
              the assets transferred.
            </p>
          </li>
          <li>
            <p>
              <strong>For other purposes</strong>: We may use Your information
              for other purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </p>
          </li>
        </ul>
        <p>
          We may share Your personal information in the following situations:
        </p>
        <ul>
          <li>
            <strong>With Service Providers:</strong> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </li>
          <li>
            <strong>For business transfers:</strong> We may share or transfer
            Your personal information in connection with, or during negotiations
            of, any merger, sale of Company assets, financing, or acquisition of
            all or a portion of Our business to another company.
          </li>
          <li>
            <strong>With Affiliates:</strong> We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </li>
          <li>
            <strong>With business partners:</strong> We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </li>
          <li>
            <strong>With other users:</strong> when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </li>
          <li>
            <strong>With Your consent</strong>: We may disclose Your personal
            information for any other purpose with Your consent.
          </li>
        </ul>
        <h5>Retention of Your Personal Data</h5>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <h5>Transfer of Your Personal Data</h5>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <h5>Delete Your Personal Data</h5>
        <p>
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </p>
        <p>
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </p>
        <p>
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </p>
        <p>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
        <h5>Disclosure of Your Personal Data</h5>
        <h4>Business Transactions</h4>
        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h4>Law enforcement</h4>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h4>Other legal requirements</h4>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <h5>Security of Your Personal Data</h5>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h4 className="mt-5">Links to Other Websites</h4>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h4 className="mt-5">Changes to this Privacy Policy</h4>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h4 className="mt-5">Contact Us</h4>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>
            <p>By email: bhiwandibroker@gmail.com</p>
          </li>
          <li>
            <p>
              By visiting this page on our website:{" "}
              <a
                href="https://bhiwandibroker.com/contact"
                rel="external nofollow noopener"
                target="_blank"
              >
                https://bhiwandibroker.com/contact
              </a>
            </p>
          </li>
        </ul>
      </div>
      {/* <div className="terms-container">
        <p>Last updated: 01 Feb 2024</p>
        <p>
          This Privacy Policy describes how Mobi Heaven (the " Site ", " we ", "
          us ", or " our ") collects, uses, and discloses your personal
          information when you visit, use our services, or make a purchase from
          mobiheaven.com (the " Site ") or otherwise communicate with us
          (collectively, the " Services ").
        </p>
        <p>
          For purposes of this Privacy Policy, " you " and " your " means you as
          the user of the Services, whether you are a customer, website visitor,
          or another individual whose information we have collected pursuant to
          this Privacy Policy.
        </p>
        <p>
          Please read this Privacy Policy carefully. By using and accessing any
          of the Services, you agree to the collection, use, and disclosure of
          your information as described in this Privacy Policy. If you do not
          agree to this Privacy Policy, please do not use or access any of the
          Services.
        </p>
        <h5 className="mt-5">CHANGES TO THIS PRIVACY POLICY</h5>
        <p>
          We may update this Privacy Policy from time to time, including to
          reflect changes to our practices or for other operational, legal, or
          regulatory reasons. We will post the revised Privacy Policy on the
          Site, update the "Last updated" date and take any other steps required
          by applicable law.
        </p>
        <h5 className="mt-5">
          HOW WE COLLECT AND USE YOUR PERSONAL INFORMATION
        </h5>
        <p>
          To provide the Services, we collect and have collected over the past
          12 months personal information about you from a variety of sources, as
          set out below. The information that we collect and use varies
          depending on how you interact with us.
        </p>
        <p>
          In addition to the specific uses set out below, we may use information
          we collect about you to communicate with you, provide the Services,
          comply with any applicable legal obligations, enforce any applicable
          terms of service, and to protect or defend the Services, our rights,
          and the rights of our users or others.
        </p>
        <h5 className="mt-5">WHAT PERSONAL INFORMATION WE COLLECT</h5>
        <p>
          The types of personal information we obtain about you depends on how
          you interact with our Site and use our Services. When we use the term
          "personal information", we are referring to information that
          identifies, relates to, describes or can be associated with you. The
          following sections describe the categories and specific types of
          personal information we collect.
        </p>
        <h5 className="mt-5">INFORMATION WE COLLECT DIRECTLY FROM YOU</h5>
        <p>
          Information that you directly submit to us through our Services may
          include:
        </p>
        <ul>
          <li>
            <b>Basic contact details: </b>
            including your name, address, phone number, email.
          </li>
          <li>
            <b>Order information: </b>
            including your name, billing address, shipping address, payment
            confirmation, email address, phone number.
          </li>
          <li>
            <b>Account information: </b>
            including your username, password, security questions.
          </li>
          <li>
            <b>Shopping information: </b>
            including the items you view, put in your cart or add to your
            wishlist.
          </li>
          <li>
            <b>Customer support information: </b>
            including the information you choose to include in communications
            with us, for example, when sending a message through the Services.
          </li>
        </ul>
        <p>
          Some features of the Services may require you to directly provide us
          with certain information about yourself. You may elect not to provide
          this information, but doing so may prevent you from using or accessing
          these features.
        </p>
        <h5 className="mt-5">INFORMATION WE COLLECT THROUGH COOKIES</h5>
        <p>
          We also automatically collect certain information about your
          interaction with the Services (" Usage Data "). To do this, we may use
          cookies, pixels and similar technologies (" Cookies ").
        </p>
        <p>
          Usage Data may include information about how you access and use our
          Site and your account, including device information, browser
          information, information about your network connection, your IP
          address and other information regarding your interaction with the
          Services.
        </p>
        <h5 className="mt-5">INFORMATION WE OBTAIN FROM THIRD PARTIES</h5>
        <p>
          Finally, we may obtain information about you from third parties,
          including from vendors and service providers who may collect
          information on our behalf, such as:
        </p>
        <p>
          - Our payment processors, who collect payment information (e.g., bank
          account, credit or debit card information, billing address) to process
          your payment in order to fulfill your orders and provide you with
          products or services you have requested, in order to perform our
          contract with you.
        </p>
        <p>
          - When you visit our Site, open or click on emails we send you, or
          interact with our Services or advertisements, we, or third parties we
          work with, may automatically collect certain information using online
          tracking technologies such as pixels, web beacons, software developer
          kits, third-party libraries, and cookies.
        </p>
        <p>
          Any information we obtain from third parties will be treated in
          accordance with this Privacy Policy. We are not responsible or liable
          for the accuracy of the information provided to us by third parties
          and are not responsible for any third party's policies or practices.
          For more information, see the section below, Third Party Websites and
          Links.
        </p>
        <h5 className="mt-5">HOW WE USE YOUR PERSONAL INFORMATION</h5>
        <p>
          <b>Providing Products and Services: </b>
          We use your personal information to provide you with the Services in
          order to perform our contract with you, including to process your
          payments, fulfill your orders, to send notifications to you related to
          you account, purchases, returns, exchanges or other transactions, to
          create, maintain and otherwise manage your account, to arrange for
          shipping, facilitate any returns and exchanges and to enable you to
          post reviews.
        </p>
        <p>
          <b>Marketing and Advertising: </b>
          We use your personal information for marketing and promotional
          purposes, such as to send marketing, advertising and promotional
          communications by email, text message or postal mail, and to show you
          advertisements for products or services. This may include using your
          personal information to better tailor the Services and advertising on
          our Site and other websites.
        </p>
        <p>
          <b>Security and Fraud Prevention: </b>
          We use your personal information to detect, investigate or take action
          regarding possible fraudulent, illegal or malicious activity. If you
          choose to use the Services and register an account, you are
          responsible for keeping your account credentials safe. We highly
          recommend that you do not share your username, password, or other
          access details with anyone else. If you believe your account has been
          compromised, please contact us immediately.
        </p>
        <p>
          <b>Communicating with you: </b>
          We use your personal information to provide you with customer support
          and improve our Services. This is in our legitimate interests in order
          to be responsive to you, to provide effective services to you, and to
          maintain our business relationship with you.
        </p>
        <h5 className="mt-5">COOKIES</h5>
        <p>
          Like many websites, we use Cookies on our Site. For specific
          information about the Cookies that we use related to powering our
          store with Shopify, see https://www.shopify.com/legal/cookies. We use
          Cookies to power and improve our Site and our Services (including to
          remember your actions and preferences), to run analytics and better
          understand user interaction with the Services (in our legitimate
          interests to administer, improve and optimize the Services). We may
          also permit third parties and services providers to use Cookies on our
          Site to better tailor the services, products and advertising on our
          Site and other websites.
        </p>
        <p>
          Most browsers automatically accept Cookies by default, but you can
          choose to set your browser to remove or reject Cookies through your
          browser controls. Please keep in mind that removing or blocking
          Cookies can negatively impact your user experience and may cause some
          of the Services, including certain features and general functionality,
          to work incorrectly or no longer be available. Additionally, blocking
          Cookies may not completely prevent how we share information with third
          parties such as our advertising partners.
        </p>
        <h5 className="mt-5">COMPLAINTS</h5>
        <p>
          If you have complaints about how we process your personal information,
          please contact us using the contact details provided below. If you are
          not satisfied with our response to your complaint, depending on where
          you live you may have the right to appeal our decision by contacting
          us using the contact details set out below, or lodge your complaint
          with your local data protection authority.
        </p>
        <h5 className="mt-5">INTERNATIONAL USERS</h5>
        <p>
          Please note that we may transfer, store and process your personal
          information outside the country you live in, including the United
          States. Your personal information is also processed by staff and third
          party service providers and partners in these countries.
        </p>
        <p>
          If we transfer your personal information out of Europe, we will rely
          on recognized transfer mechanisms like the European Commission's
          Standard Contractual Clauses, or any equivalent contracts issued by
          the relevant competent authority of the UK, as relevant, unless the
          data transfer is to a country that has been determined to provide an
          adequate level of protection.
        </p>
        <h5 className="mt-5">CONTACT</h5>
        <p>
          Should you have any questions about our privacy practices or this
          Privacy Policy, or if you would like to exercise any of the rights
          available to you, please call or email us at stickitup.xyz@gmail.com
          or contact us at Arthi Road, Dahisar East, behind Rajshree Cinema,
          400068 Mumbai MH, India.
        </p>
        <p> Mobi Heaven is an IP of THE TOP TIER</p>
      </div> */}
    </Layout>
  );
};

export default PrivacyPolicy;
