import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import "./AdminViewProperties.css";

const AdminViewProperties = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [property, setProperty] = useState(null);
  const [image, setImage] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getProperty() {
    try {
      const res = await axios.post(
        "/api/property/get-property",
        {
          bbId: params?.bbId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProperty(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleVerify() {
    try {
      const res = await axios.post(
        "/api/admin/verify-property",
        {
          bbId: params?.bbId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/admin-properties");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProperty();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-view-property-container">
        <div className="page-title">
          <h3 className="m-0">{params?.bbId}</h3>
          <button onClick={() => navigate("/admin-add-product")}>Verify</button>
        </div>
        <hr />
        <div className="admin-product-info-container">
          <div className="product-info-container-left">
            <img
              className="main-img"
              src={property && property?.images[image]}
              alt=""
            />
            <div className="img-slider">
              {property?.images?.map((item, index) => {
                return (
                  <img
                    onClick={() => setImage(index)}
                    className={`${index === image && "active"}`}
                    src={item}
                    alt="image"
                  />
                );
              })}
            </div>
            <div className="product-about my-4">
              <h5>{property?.title}</h5>
              <hr />
              <h6>About</h6>
              <span>
                <small>{property?.about}</small>
              </span>
              {/* <hr />
            <h6>Address</h6>
            <span>
              <small>{property?.address}</small>
            </span> */}
            </div>
            {/* <hr /> */}
            {/* <div className="product-highlights">
            <h6>Highlights</h6>
            <span>
              <small>Air Conditioner</small>
            </span>
            <span>
              <small>Furnished</small>
            </span>
            <span>
              <small>Front Facing</small>
            </span>
            <span>
              <small>Water Supply</small>
            </span>
          </div> */}
            <hr />
            <h6>Details</h6>
            <div className="product-details">
              <div className="details">
                <span>
                  <small>Type :</small>
                </span>
                <span>
                  <small>
                    {property?.type === "Sell" ? "Sale" : property?.type}
                  </small>
                </span>
              </div>
              <div className="details">
                <span>
                  <small>Category :</small>
                </span>
                <span>
                  <small>{property?.category}</small>
                </span>
              </div>
              {property?.category === "House" && (
                <div className="details">
                  <span>
                    <small>Bedroom :</small>
                  </span>
                  <span>
                    <small>{property?.bedroom}</small>
                  </span>
                </div>
              )}
              {property?.category === "House" && (
                <div className="details">
                  <span>
                    <small>Bathrooms :</small>
                  </span>
                  <span>
                    <small>{property?.bathroom}</small>
                  </span>
                </div>
              )}
              {(property?.category === "House" ||
                property?.category === "Shop/Office") && (
                <div className="details">
                  <span>
                    <small>Furnishing :</small>
                  </span>
                  <span>
                    <small>{property?.furnishing}</small>
                  </span>
                </div>
              )}
              {property?.category === "House" && (
                <div className="details">
                  <span>
                    <small>Construction Status :</small>
                  </span>
                  <span>
                    <small>{property?.pStatus}</small>
                  </span>
                </div>
              )}
              <div className="details">
                <span>
                  <small>Posted By :</small>
                </span>
                <span>
                  <small>{property?.postedBy}</small>
                </span>
              </div>
              {(property?.category === "House" ||
                property?.category === "Shop/Office") && (
                <div className="details">
                  <span>
                    <small>Builtup Area :</small>
                  </span>
                  <span>
                    <small>{property?.builtupArea}</small>
                  </span>
                </div>
              )}
              {(property?.category === "House" ||
                property?.category === "Shop/Office") && (
                <div className="details">
                  <span>
                    <small>Carpet Area :</small>
                  </span>
                  <span>
                    <small>{property?.carpetArea}</small>
                  </span>
                </div>
              )}
              {(property?.category === "House" ||
                property?.category === "Shop/Office") && (
                <div className="details">
                  <span>
                    <small>Maintenance :</small>
                  </span>
                  <span>
                    <small>₹{property?.maintenanceCost}</small>
                  </span>
                </div>
              )}
              {property?.category === "House" && (
                <div className="details">
                  <span>
                    <small>Total Floors :</small>
                  </span>
                  <span>
                    <small>{property?.totalFloors}</small>
                  </span>
                </div>
              )}
              {property?.category === "House" && (
                <div className="details">
                  <span>
                    <small>Floor No. :</small>
                  </span>
                  <span>
                    <small>{property?.floorNumber}</small>
                  </span>
                </div>
              )}
              {(property?.category === "House" ||
                property?.category === "Shop/Office") && (
                <div className="details">
                  <span>
                    <small>Parking :</small>
                  </span>
                  <span>
                    <small>{property?.parking}</small>
                  </span>
                </div>
              )}
              {property?.category === "Plot/Land" && (
                <div className="details">
                  <span>
                    <small>Plot Area :</small>
                  </span>
                  <span>
                    <small>
                      {property?.plotArea} {property?.plotAreaUnit}
                    </small>
                  </span>
                </div>
              )}
              {property?.category === "Plot/Land" && (
                <div className="details">
                  <span>
                    <small>Plot Length :</small>
                  </span>
                  <span>
                    <small>
                      {property?.plotLength} {property?.plotAreaUnit}
                    </small>
                  </span>
                </div>
              )}
              {property?.category === "Plot/Land" && (
                <div className="details">
                  <span>
                    <small>Plot Breadth :</small>
                  </span>
                  <span>
                    <small>
                      {property?.plotBreadth} {property?.plotAreaUnit}
                    </small>
                  </span>
                </div>
              )}
              {property?.category === "Plot/Land" && (
                <div className="details">
                  <span>
                    <small>Plot Open Sides :</small>
                  </span>
                  <span>
                    <small>{property?.plotOpenSides}</small>
                  </span>
                </div>
              )}
              {property?.category === "Plot/Land" && (
                <div className="details">
                  <span>
                    <small>Any Construction :</small>
                  </span>
                  <span>
                    <small>{property?.plotConsDone}</small>
                  </span>
                </div>
              )}
              {property?.category === "Shop/Office" && (
                <div className="details">
                  <span>
                    <small>Washroom :</small>
                  </span>
                  <span>
                    <small>{property?.washroom}</small>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="product-info-container-right form-sticky">
            <div className="product-price">
              <h4>
                <b>
                  ₹{property?.price} {property?.type === "Rent" && "Monthly"}
                </b>
              </h4>
              <div>
                <ShareIcon />
                <FavoriteBorderIcon />
              </div>
            </div>
            <div className="more-details">
              {property?.category === "House" ? (
                <h6>
                  {property?.bedroom}BHK - {property?.bathroom}BA -{" "}
                  {property?.builtupArea} sqft
                </h6>
              ) : property?.category === "Shop/Office" ? (
                <h6>{property?.builtupArea} sqft</h6>
              ) : property?.category === "Plot/Land" ? (
                <h6>{property?.plotArea} sqft</h6>
              ) : (
                ""
              )}
              <span className="text-muted d-flex justify-content-between">
                <small>{property?.area}</small>
                <small>
                  {new Date(property?.created).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                  })}
                </small>
              </span>
            </div>
            <button
              className={`w-100 mt-3 bb-btn ${
                property?.verified && "bg-danger"
              }`}
              onClick={handleVerify}
            >
              {property?.verified ? "Unverify Property" : "Verify Property"}
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminViewProperties;
