import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="bg-heading">
        <h4>
          <b>Terms And Conditions</b>
        </h4>
      </div>
      <div className="terms-container">
        <h5>1. Acceptance of Terms</h5>
        <p>
          By accessing and using the website [www.bhiwandibroker.com], you agree
          to be bound by these Terms and Conditions and all applicable laws and
          regulations. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site.
        </p>
        <h5 className="mt-5">2. Registration and User Accounts:</h5>
        <p>
          a. Users must provide accurate and complete information during the
          registration process.
        </p>
        <p>
          b. Users are responsible for maintaining the confidentiality of their
          account information.
        </p>
        <p>
          c. Users agree to notify the website immediately of any unauthorized
          use of their account.{" "}
        </p>
        <h5 className="mt-5">3. Property Listings</h5>
        <p>a. The website serves as a platform for real estate listings.</p>
        <p>
          b. Bhiwandi Broker reserves the right to modify or remove any property
          listing at its discretion.
        </p>
        <p>
          c. Users are responsible for the accuracy of the information provided
          in their property listings.
        </p>

        <h5 className="mt-5">4. Transactions </h5>
        <p>
          a. Bhiwandi Broker facilitates communication between buyers, renters,
          and property owners.
        </p>
        <p>
          b. Transactions are solely between the parties involved, and Bhiwandi
          Broker is not responsible for any disputes. Bhiwandi Broker has agents
          who will take responsiblilties as a witness between buyers and
          sellers.
        </p>
        <h5 className="mt-5">5. User Conduct</h5>
        <p>
          a. Users agree not to engage in any unlawful or prohibited activities
          on the website.
        </p>
        <p>
          b. Users must respect the privacy and rights of other users and
          property owners.
        </p>
        <h5 className="mt-5">6. Intellectual Property</h5>
        <p>
          a. All content on the website is the property of Bhiwandi Broker and
          is protected by intellectual property laws.
        </p>
        <p>
          b. Users may not reproduce, distribute, or use any content without
          explicit permission.
        </p>
        <h5 className="mt-5">7. Privacy Policy</h5>
        <p>
          a. The website's privacy policy outlines how user information is
          collected, used, and protected.
        </p>
        <p>
          b. Users are encouraged to review the privacy policy to understand how
          their data is handled.
        </p>
        <h5 className="mt-5">8. Limitation of Liability</h5>
        <p>
          a. Bhiwandi Broker is not liable for any direct, indirect, or
          consequential damages arising from the use of the website.
        </p>
        <p>b. Users use the website at their own risk.</p>
        <h5 className="mt-5">9. Termination</h5>
        <p>
          a. Bhiwandi Broker reserves the right to terminate or suspend user
          accounts for violation of these terms.
        </p>
        <h5 className="mt-5">10. Governing Law</h5>
        <p>
          a. These terms and conditions are governed by the laws of
          [Jurisdiction] without regard to its conflict of law principles.
        </p>
        <h5 className="mt-5">11. Changes to Terms</h5>
        <p>
          a. Bhiwandi Broker reserves the right to update or modify these terms
          and conditions at any time.
        </p>
        <p>
          By using the Bhiwandi Broker real estate website, you acknowledge that
          you have read, understood, and agreed to these terms and conditions.
        </p>
        <div className="mt-5">
          <p className="m-0">
            <b>Bhiwand Broker</b>
          </p>
          <p className="m-0">
            <>bhiwandibroker@gmail.com</>
          </p>
          <p className="m-0">
            <>01 Feb 2024</>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
