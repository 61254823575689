import React, { useEffect, useState } from "react";
import "./Products.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import IMAGES from "../img/image";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import SelectAllOutlinedIcon from "@mui/icons-material/SelectAllOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HomeIcon from "@mui/icons-material/Home";
import { message } from "antd";

const Products = ({ title, homeLabel }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState(null);

  async function getAllProperties() {
    try {
      const res = await axios.get("/api/property/get-all-property");
      if (res.data.success) {
        setProperties(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllProperties();
  }, []);

  return (
    <>
      <div className="products-container">
        {properties &&
          properties
            .map((item, index) => {
              return (
                <div
                  className="product"
                  onClick={() => navigate(`/property-info/${item?.bbId}`)}
                >
                  <div className="pro-img">
                    <img src={item?.images[0]} alt="" />
                    <div className="pro-location">
                      <div className="item-center">
                        <LocationOnIcon className="icon" />
                        <h5 className="m-0">
                          <small>{item?.area}</small>
                        </h5>
                      </div>
                      <div className="item-center gap-1">
                        <CameraAltIcon />
                        {item?.images?.length}
                      </div>
                    </div>
                  </div>
                  <div className="bb-product-content">
                    <h6 className="mt-3 mb-0">{item?.title}</h6>
                    <span className="text-muted">
                      {item?.category === "House"
                        ? item?.bedroom + " BHK Flat "
                        : item?.category === "Plot/Land"
                        ? item?.plotArea + " " + item?.plotAreaUnit + " Plot "
                        : item?.category === "Shop/Office"
                        ? item?.builtupArea + " sqft Shop/Office "
                        : ""}
                      on {item?.type === "Sell" ? "Sale" : "Rent"} in{" "}
                      {item?.area}
                    </span>
                    <h6>
                      Price - ₹{parseInt(item?.price).toLocaleString("en-US")}
                      {item?.type === "Rent" && "/Month"}
                    </h6>
                    <div className="bb-date-contact">
                      <span>
                        <small>
                          Posted by <b>{item?.postedBy}</b>
                        </small>
                      </span>
                      <span>
                        <small>
                          {new Date(item?.created).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
            .slice(0, 9)}
        <div className="w-100">
          <button
            onClick={() => navigate("/properties")}
            className="bb-btn d-block m-auto"
          >
            View All Properties
          </button>
        </div>
      </div>
    </>
  );
};

export default Products;
