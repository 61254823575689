import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const AdminOrder = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllOrders = async (e) => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/admin-get-all-orders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setOrders(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">All Orders</h3>
        </div>
        <hr />
        <div className="table-container">
          <table className="table order-table">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Email</th>
                <th>Total</th>
                <th>Status</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <small>{item?.orderId}</small>
                    </td>
                    <td>
                      <small>{item?.email}</small>
                    </td>
                    <td>
                      <small>{item?.total}</small>
                    </td>
                    <td>
                      <small>{item?.status}</small>
                    </td>
                    <td>
                      <small>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </small>
                    </td>
                    <td>
                      <RemoveRedEyeIcon
                        onClick={() =>
                          navigate(`/admin-view-order/${item?.orderId}`)
                        }
                        className="text-success icon"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminOrder;
