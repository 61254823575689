import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { message, Modal } from "antd";
import { setUser } from "../../redux/features/userSlice";
import axios from "axios";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import MenuIcon from "@mui/icons-material/Menu";
import SearchContainer from "../SearchContainer";
import CartSideMenu from "../CartSideMenu";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [homeLabel, setHomeLabel] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      {/* <div className="location">
        <div>
          <LocationOnIcon />
          Bhiwandi
        </div>
        <div>Login | Sign Up</div>
      </div> */}
      <header className={`header ${location.pathname !== "/" && "active m-0"}`}>
        <div className="header-main">
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div className="logo line" onClick={() => navigate("/")}>
            <span className="fs-5">BhiwandiBroker</span>
          </div>
          <div className="menus d-none d-md-none d-lg-block">
            <ul className="p-0">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/properties">Properties</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li onClick={() => navigate("/#learn-video")}>
                <Link className="text-warning">Learn to Post a Property?</Link>
              </li>
              {/* <li>
                <Link to="/blog">Blogs</Link>
              </li> */}
              {user ? (
                <button
                  className="loginbtn"
                  onClick={() => navigate("/user-dashboard")}
                >
                  <PersonIcon className="icon me-1" />
                  Dashboard
                </button>
              ) : (
                <button className="loginbtn" onClick={() => navigate("/login")}>
                  <PersonIcon className="icon me-1" />
                  Login
                </button>
              )}
              {/* <button className="location-btn">
                <LocationOnIcon />
              </button> */}
              <button
                className="post-btn"
                onClick={() =>
                  user ? navigate("/add-property") : navigate("/login")
                }
              >
                <small>Add Property</small>
                <AddCircleIcon className="icon ms-1" />
              </button>
            </ul>
          </div>
          <div className="action-btns d-block d-lg-none">
            <div
              className="burger-icon d-block d-lg-none"
              onClick={() => setSideMenu(!sideMenu)}
            >
              {user
                ? location.pathname !== "/user-dashboard" && (
                    <button
                      className="sm-btn d-block d-md-none d-lg-none"
                      onClick={() => navigate("/user-dashboard")}
                    >
                      Dashboard
                    </button>
                  )
                : location.pathname !== "/login" && (
                    <button
                      className="sm-btn d-block d-md-none d-lg-none"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </button>
                  )}
              <DragHandleIcon className="icon" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
