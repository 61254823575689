import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./PhoneSkins.css";
import IMAGES from "../img/image";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import HowItWorks from "../components/Home/HowItWorks";
import ReactSlider from "react-slider";
import Pagination from "../components/Pagination";

const PhoneSkins = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [filterBox, setFilterBox] = useState(null);
  const [ui, setUi] = useState(4);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState("latest");
  const [homeLabel, setHomeLabel] = useState(null);
  //! pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to the products container
    const productsContainer = document.querySelector(".product-filter");
    if (productsContainer) {
      productsContainer.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchShippingCharge = async () => {
    try {
      const res = await axios.get("/api/admin/get-shipping-charge");
      if (res.data.success) {
        setHomeLabel(res.data.data[0].homeLabel);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching shipping charge:", error);
    }
  };

  const filterProducts = (sort) => {
    if (sort === "htl") {
      setLoading(true);
      const sortedProducts =
        products && products.reverse().sort((a, b) => b.price - a.price);
      setProducts(sortedProducts);
      setSorting("htl");
      setLoading(false);
    }
    if (sort === "lth") {
      setLoading(true);
      const sortedProducts =
        products && products.reverse().sort((a, b) => a.price - b.price);
      setProducts(sortedProducts);
      setSorting("lth");
      setLoading(false);
    }
  };

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts =
    products && products.slice(indexOfFirstProduct, indexOfLastProduct);

  useEffect(() => {
    fetchShippingCharge();
    setTimeout(() => {
      getAllProducts();
    }, 1000);
  }, []);

  const Products = () => {
    return (
      <div className="products-container phone-skins-products-container">
        <div className="product-title text-center">
          <div className="products">
            {loading ? (
              <div className="loader-container text-center">
                <span class="loader"></span>
              </div>
            ) : (
              currentProducts &&
              currentProducts.map((product, index) => {
                const width = 100 / ui - 2;
                return (
                  <div
                    onClick={() => navigate(`/product/${product?.name}`)}
                    key={index}
                    className="product text-start"
                    style={{ width: `${width}%` }}
                  >
                    <div className="product-img-cont">
                      <div className="sale">SALE</div>
                      <img src={product?.images[0]} alt="" />
                      <div className="discount">
                        {homeLabel !== 0 && <span>{homeLabel}% OFF</span>}
                      </div>
                    </div>
                    <div className="product-name">
                      <h6>{product?.name}</h6>
                    </div>
                    <span className="m-0 p-0">
                      <span className="d-price">Rs. {product?.price}</span>
                      <span className="o-price">
                        Rs.
                        {parseInt(product?.price) +
                          parseInt((product?.price * homeLabel) / 100)}
                      </span>
                    </span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="phone-skins-container">
        <img src={IMAGES.phoneskinbanner} alt="" />
      </div>
      <div className="product-filter">
        <div className="left-filter" onClick={() => setFilterBox(!filterBox)}>
          <FilterAltIcon className="icon" />
          <b>FILTER</b>
        </div>
        <div className="right-filter">
          <div className="total-products">
            <b>{products?.length} Products</b>
          </div>
          <div
            onClick={() => setUi(2)}
            className={`two-product-ui ${ui === 2 && "active"}`}
          >
            <b>||</b>
          </div>
          <div
            onClick={() => setUi(3)}
            className={`three-product-ui ${ui === 3 && "active"}`}
          >
            <b>|||</b>
          </div>
          <div
            onClick={() => setUi(4)}
            className={`four-product-ui ${ui === 4 && "active"}`}
          >
            <b>||||</b>
          </div>
          <div
            onClick={() => setUi(5)}
            className={`five-product-ui ${ui === 5 && "active"}`}
          >
            <b>|||||</b>
          </div>
          {/* <div className="sort-by">
            <SortIcon className="icon" />
            <b>SORT BY</b>
          </div> */}
        </div>
      </div>
      <hr className="m-0" />
      <div className={`filter-box ${filterBox && "active"}`}>
        <div className="d-flex justify-content-end">
          <CloseIcon
            className="icon"
            onClick={() => setFilterBox(!filterBox)}
          />
        </div>
        <div className="my-5 filter-title d-flex justify-content-between align-items-center">
          <h4 className="m-0">Filter Options</h4>
          {(sorting === "lth" || sorting === "htl") && (
            <p
              onClick={() => {
                setFilterBox(!filterBox);
                setSorting("latest");
                setLoading(true);
                setTimeout(() => {
                  getAllProducts();
                  setLoading(false);
                }, 2000);
              }}
              className="m-0 text-decoration-underline"
            >
              Clear
            </p>
          )}
        </div>
        <div className="filter-options">
          <div
            className={`sorting ${sorting === "latest" && "active"}`}
            onClick={() => {
              setSorting("latest");
              setFilterBox(!filterBox);
              getAllProducts();
            }}
          >
            <span>Lastest Products</span>
          </div>
          <span
            className={`sorting ${sorting === "lth" && "active"}`}
            onClick={() => {
              filterProducts("lth");
              setFilterBox(!filterBox);
            }}
          >
            Price Low to High
          </span>
          <span
            className={`sorting ${sorting === "htl" && "active"}`}
            onClick={() => {
              filterProducts("htl");
              setFilterBox(!filterBox);
            }}
          >
            Price High to Low{" "}
          </span>
        </div>
      </div>
      <div
        onClick={() => setFilterBox(!filterBox)}
        className={`filter-box-drop ${filterBox && "dactive"}`}
      ></div>
      <div className="phone-skin-product-container">
        <Products />
        <Pagination
          currentPage={currentPage}
          paginate={paginate}
          products={products}
          productsPerPage={productsPerPage}
        />
      </div>
      <HowItWorks />
    </Layout>
  );
};

export default PhoneSkins;
