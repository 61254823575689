import React, { useEffect, useRef } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import SliderText from "../components/Home/SliderText";
import Products from "../components/Products";
import SubscriberForm from "../components/Home/SubscriberForm";
import Transformation from "../components/Home/Transformation";
import HomeGallery from "../components/Home/HomeGallery";
import axios from "axios";
import { message } from "antd";
import HomeCategories from "../components/Home/HomeCategories";
import WhyChooseUs from "./WhyChooseUs";
import ExploreCity from "./ExploreCity";
import LearnVideo from "./LearnVideo.js";
import Agents from "./Agents";
import { useLocation, useNavigate } from "react-router-dom";
import Testimonials from "../components/Home/Testimonails";

import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null); // Create a reference
  const location = useLocation();

  const scrollToVideo = () => {
    videoRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (location.hash === "#learn-video") {
      scrollToVideo();
    }
  }, [location]);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <HeroSection />
      <SliderText
        text={"TRENDING PROPERTIES IN BHIWANDI"}
        direction={"right"}
        bg={"#ffffff"}
        fs={25}
      />
      <div className="home-categories-container">
        <span className="m-0">
          <span>
            <small className="text-green">
              <b>BROWSE HOT OFFERS</b>
            </small>
          </span>
          <br />
          <h4 className="m-0">Latest Properties</h4>
        </span>
        <div className="categories">
          <button
            onClick={() => navigate("/properties")}
            className="bb-btn m-0"
          >
            See All
          </button>
        </div>
      </div>
      <Products />
      <div ref={videoRef}>
        <LearnVideo />
      </div>
      <ExploreCity />
      <Agents />
      <HowItWorks />
      <Testimonials />
      {/* <SubscriberForm /> */}
      {/* <Transformation /> */}
      {/* <HomeGallery /> */}
      {/* <WhyChooseUs /> */}
      {/* <Products homeLabel={homeLabel} title={"New Arrivals"} /> */}
    </Layout>
  );
};

export default Home;
