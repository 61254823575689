import React from "react";
import "./Pagination.css";

const Pagination = ({ currentPage, paginate, products, itemsPerPage }) => {
  const totalPages = Math.ceil(products?.length / itemsPerPage);

  const displayPageNumbers = () => {
    const limit = 2; // Adjust this limit as per your requirement
    if (totalPages <= limit) {
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    }
    return [];
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
    }
  };

  return (
    <div className="pagination">
      <div
        className={`page-number ${currentPage === 1 ? "disabled" : ""}`}
        onClick={handlePrevClick}
      >
        Prev
      </div>
      <div
        className={`page-number ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={handleNextClick}
      >
        Next
      </div>
    </div>
  );
};

export default Pagination;
