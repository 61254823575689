import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import HowItWorks from "../components/Home/HowItWorks";
import { Link, useNavigate } from "react-router-dom";
import "./Cart.css";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setDiscount } from "../redux/features/discountSlice";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { discount } = useSelector((state) => state.discount);
  // shipping n coupon
  const [shippingCharge, setShippingCharge] = useState(0);
  const [data, setData] = useState({});
  const [discountApplied, setDiscountApplied] = useState(false);
  const [enteredCoupon, setEnteredCoupon] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  console.log(data);

  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [total, setTotal] = useState(0);
  const updateCart = (updatedCart) => {
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };
  const calculateTotal = () => {
    const newTotal = cart.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setTotal(newTotal);
  };

  const handleRemoveItem = (item) => {
    const updatedCart = cart.map((cartItem) => {
      if (cartItem.id === item.id) {
        const updatedQuantity = Math.max(1, cartItem.quantity - 1);
        return { ...cartItem, quantity: updatedQuantity };
      }
      return cartItem;
    });

    updateCart(updatedCart);
  };

  const handleAddItem = (item) => {
    const updatedCart = cart.map((cartItem) => {
      if (cartItem.id === item.id) {
        const updatedQuantity = cartItem.quantity + 1;
        return { ...cartItem, quantity: updatedQuantity };
      }
      return cartItem;
    });
    updateCart(updatedCart);
  };
  const handleDeleteItem = (item) => {
    const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
    updateCart(updatedCart);
  };
  useEffect(() => {
    calculateTotal();
  }, [cart]);

  const fetchShippingCharge = async () => {
    try {
      const res = await axios.get("/api/admin/get-shipping-charge");
      if (res.data.success) {
        setShippingCharge(res.data.data[0].shippingCharge);
        setData(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching shipping charge:", error);
    }
  };

  useEffect(() => {
    fetchShippingCharge();
  }, []);

  const handleApplyDiscount = (e) => {
    e.preventDefault();
    if (enteredCoupon === "") {
      setError("Enter coupon");
      setTimeout(() => {
        setError("");
      }, 1500);
      return;
    }
    const coupon = data?.coupons?.find(
      (coupon) => coupon.name === enteredCoupon
    );
    if (coupon) {
      setDiscountApplied(true);
      setSuccess("Coupon applied success");
      setTimeout(() => {
        setSuccess("");
      }, 1500);
      dispatch(setDiscount(coupon.discount));
      // setDiscount(coupon.discount);
    } else {
      setError("No coupon found");
      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };

  return (
    <Layout>
      <div className="cart-container container">
        <div className={`cart-bag ${cart?.length === 0 && "w-100"}`}>
          <h3 className="m-0">Your Cart</h3>
          {cart && cart?.length === 0 && <hr className="d-none d-lg-block" />}
          <hr className="d-block d-lg-none" />
          <div className="cart-item-container">
            {cart && cart?.length === 0 ? (
              <div
                style={{
                  minHeight: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="cart-items"
              >
                <ProductionQuantityLimitsIcon className="icon" />
                <span>Your cart is empty</span>
                <Link to="/phone-skins">Continue Shopping</Link>
              </div>
            ) : (
              cart &&
              cart?.map((item, index) => {
                return (
                  <div key={index} className="cart-item">
                    <div className="cart-item-img">
                      <img src={item?.images[0]} alt="" />
                    </div>
                    <div className="cart-item-details">
                      <span className="m-0 p-0">{item?.name}</span>
                      <span>MODEL: {item?.selectedModel}</span>
                      <span>
                        <b>Rs. {item?.price}</b>
                      </span>
                    </div>
                    <div className="cart-item-quantity">
                      <RemoveIcon
                        onClick={() => handleRemoveItem(item)}
                        className="icon"
                      />
                      <span>{item?.quantity}</span>
                      <AddIcon
                        onClick={() => handleAddItem(item)}
                        className="icon"
                      />
                    </div>
                    <div className="cart-item-price">
                      <h5 className="m-0">Rs.{item?.price * item?.quantity}</h5>
                    </div>
                    <div className="cart-item-delete">
                      <DeleteIcon
                        className="icon m-0"
                        onClick={() => handleDeleteItem(item)}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        {cart && cart.length > 0 && (
          <div className="cart-total">
            <h4>Price Details</h4>
            <hr className="m-0 mb-3" />
            <div className="d-flex justify-content-between">
              <h6>Subtotal</h6>
              <h6 className="bold-text">Rs. {total}</h6>
            </div>
            <div className="d-flex justify-content-between">
              <h6>Shipping</h6>
              <h6 className="bold-text">
                {shippingCharge === 0 ? "Free" : "+ Rs." + shippingCharge}
              </h6>
            </div>
            {discountApplied && (
              <div className="d-flex justify-content-between">
                <h6>Discount</h6>
                <h6 className="bold-text">
                  - Rs. {discount}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setDiscountApplied(false)}
                    className="ms-2"
                  >
                    <small>(remove)</small>
                  </span>
                </h6>
              </div>
            )}
            <div className="d-flex justify-content-between">
              <h6>Total</h6>
              <h4 className="m-0">
                <b>
                  Rs. {total + shippingCharge - (discountApplied && discount)}
                </b>
              </h4>
            </div>
            <div className="apply-code-container">
              <input
                onChange={(e) => setEnteredCoupon(e.target.value)}
                type="text"
                name="coupon"
                placeholder="Discount code"
              />
              <button onClick={handleApplyDiscount}>Apply</button>
            </div>
            {error !== "" && (
              <span className="text-danger m-0">
                <small>{error}</small>
              </span>
            )}
            {success !== "" && (
              <span className="text-success m-0">
                <small>{success}</small>
              </span>
            )}
            <button
              onClick={() => navigate("/checkout")}
              className="w-100 add-to-cart-btn m-0"
            >
              Checkout
            </button>
            <span className="text-center m-0">
              <small>Taxes & Shipping calculated at checkout</small>
            </span>
          </div>
        )}
      </div>
      <HowItWorks />
    </Layout>
  );
};

export default Cart;
