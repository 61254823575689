import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import Slider from "react-slider";
import axios from "axios";
import bhiwandiAreas from "../bhiwandiAreas";
import Pagination from "../components/Pagination";
import NoResultFound from "../components/UI/NoResultFound";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductPage.css";

const MIN = 0;
const MAX = 5000000;
const MINSQFT = 0;
const MAXSQFT = 500000;

const ProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [budget, setBudget] = useState([MIN, MAX]);
  const [budgetInput, setBudgetInput] = useState(false);
  const [minBudgetInput, setMinBudgetInput] = useState(MIN);
  const [maxBudgetInput, setMaxBudgetInput] = useState(MAX);

  const handleMinBudgetChange = (event) => {
    const newMinBudget = parseInt(event.target.value, 10);
    setMinBudgetInput(newMinBudget);
    setBudget([newMinBudget, budget[1]]);
  };

  const handleMaxBudgetChange = (event) => {
    const newMaxBudget = parseInt(event.target.value, 10);
    setMaxBudgetInput(newMaxBudget);
    setBudget([budget[0], newMaxBudget]);
  };
  const [sqft, setSqft] = useState([MINSQFT, MAXSQFT]);
  const [isSticky, setSticky] = useState(false);
  const [properties, setProperties] = useState(false);
  const [filter, setFilter] = useState({
    area: "",
    type: "",
    category: "",
    bedroom: "",
    furnishing: "",
    postedBy: "",
  });
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [error, setError] = useState(false);

  //! FILTER
  const [showFilter, setShowFilter] = useState(false);
  const [filterBackDrop, setFilterBackDrop] = useState(false);

  //! PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // Function to change the current page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function handleChange(e) {
    const { value, name } = e.target;
    setFilter({ ...filter, [name]: value });
    if (name === "area") {
      const isValidArea = bhiwandiAreas?.data?.some((item) => {
        return item.name === value;
      });
      if (isValidArea) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }

  async function getAllProperties() {
    try {
      const res = await axios.get("/api/property/get-all-property");
      if (res.data.success) {
        setProperties(res.data.data.reverse());
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleClearFilter() {
    setFilter({
      area: "",
      type: "",
      category: "",
      bedroom: "",
      furnishing: "",
      postedBy: "",
    });
    setBudget([0, 10000000]);
    setSqft([0, 500000]);
  }

  useEffect(() => {
    getAllProperties();
    const handleScroll = () => {
      setSticky(window.scrollY > 30);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(properties)) {
      const newFilteredProperties = properties?.filter((property) => {
        // Check each filter criterion individually
        const areaMatch = filter.area ? property.area === filter.area : true;
        const typeMatch = filter.type ? property.type === filter.type : true;
        const categoryMatch = filter.category
          ? property.category === filter.category
          : true;
        const bedroomMatch =
          property.category === "House" && filter.bedroom
            ? property.bedroom === filter.bedroom
            : true;
        const furnishingMatch =
          (property.category === "House" ||
            property.category === "Shop/Office") &&
          filter.furnishing
            ? property.furnishing === filter.furnishing
            : true;
        const postedByMatch = filter.postedBy
          ? property.postedBy === filter.postedBy
          : true;

        // Check if price is within the budget range
        const budgetMatch =
          property.price >= budget[0] && property.price <= budget[1];

        // Check if area is within the sqft range
        const sqftMatch =
          property.category === "House" || property.category === "Shop/Office"
            ? property.builtupArea >= sqft[0] && property.builtupArea <= sqft[1]
            : property.plotArea >= sqft[0] && property.plotArea <= sqft[1];

        // Combine all conditions with AND logic
        return (
          areaMatch &&
          typeMatch &&
          categoryMatch &&
          bedroomMatch &&
          furnishingMatch &&
          postedByMatch &&
          budgetMatch &&
          sqftMatch
        );
      });

      setFilteredProperties(newFilteredProperties);
    }
  }, [filter, properties, budget, sqft]);

  useEffect(() => {
    if (params?.area) {
      setFilter((prev) => ({ ...prev, area: params?.area }));
    }
  }, []);

  return (
    <Layout>
      <div className="product-page-container">
        {/* FILTER UI  */}
        <div
          className="mobile-menu d-block d-md-block d-lg-none"
          onClick={() => {
            setShowFilter(!showFilter);
            setFilterBackDrop(!filterBackDrop);
          }}
        >
          <div className="filter-btns">
            <div>
              <FilterAltIcon className="icon" />
              <span>Filter</span>
            </div>
            <span className="ms-5">
              <b>{currentItems?.length}</b>
            </span>
          </div>
        </div>
        {/* FILTER BACKDROP  */}
        <div
          className={`db-menu-backdrop ${filterBackDrop && "active"}`}
          onClick={() => {
            setShowFilter(false);
            setFilterBackDrop(false);
          }}
        ></div>
        <div
          className={`mobile-filter-container ${
            showFilter && "active"
          } d-block d-md-block d-lg-none`}
        >
          <CancelIcon
            onClick={() => {
              setShowFilter(!showFilter);
              setFilterBackDrop(!filterBackDrop);
            }}
            className="icon"
          />

          {/* MOBILE FILTER  */}
          <div className="mobile-filter">
            <div className="category">
              <div className="form-fields mb-2">
                <h6>
                  <small>Location</small>
                </h6>
                <input
                  type="text"
                  list="areas"
                  name="area"
                  value={filter?.area}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Choose Area"
                />
                <datalist id="areas" placeholder="">
                  {bhiwandiAreas?.data?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </datalist>
                <small className="text-danger">
                  {error && "Please select area from the list"}
                </small>
              </div>
              <div className="form-fields mb-2">
                <h6>
                  <small>Type</small>
                </h6>
                <select
                  onChange={handleChange}
                  value={filter?.type}
                  className="form-select"
                  name="type"
                >
                  <option value="All">Select </option>
                  <option value="Sell">Buy</option>
                  <option value="Rent">Rent</option>
                </select>
              </div>
            </div>
            <div className="category">
              {/* <h6>Categories</h6> */}
              <div className="form-fields mb-2">
                <h6>
                  <small>Category</small>
                </h6>
                <select
                  onChange={handleChange}
                  value={filter?.category}
                  className="form-select"
                  name="category"
                >
                  <option value="select">Select</option>
                  <option value="House">House</option>
                  <option value="Shop/Office">Shop/Office</option>
                  <option value="Plot/Land">Plot/Land</option>
                </select>
              </div>
            </div>
            <hr />
            <h6>
              <small>Budget</small>
            </h6>
            <span>
              <b>
                ₹{budget[0]} - ₹{budget[1]}
              </b>
            </span>
            <Slider
              className={"slider"}
              value={budget}
              min={MIN}
              max={MAX}
              minDistance={2000}
              onChange={setBudget}
            />
            <hr />
            <h6>
              <small>AREA SQFT</small>
            </h6>
            <span>
              <b>{sqft[0]}</b> <small>sqft</small> - <b>{sqft[1]}</b>{" "}
              <small>sqft</small>
            </span>
            <Slider
              className={"slider"}
              value={sqft}
              min={MINSQFT}
              max={MAXSQFT}
              onChange={setSqft}
            />
            <hr />
            {filter?.category === "House" && (
              <>
                <h6>
                  <small>No. of Bedrooms</small>
                </h6>
                <div className="form-fields mb-2">
                  <select
                    onChange={handleChange}
                    value={filter?.bedroom}
                    className="form-select"
                    name="bedroom"
                  >
                    <option value="Select">Select</option>
                    <option value="1">1 BHK</option>
                    <option value="2">2 BHK</option>
                    <option value="3">3 BHK</option>
                    <option value="4">4 BHK</option>
                    <option value="5">5 BHK</option>
                    <option value="6">6 BHK</option>
                    <option value="7">7 BHK</option>
                    <option value="8">8 BHK</option>
                    <option value="9">9 BHK</option>
                    <option value="9">9 BHK</option>
                    <option value="10">10 BHK</option>
                  </select>
                </div>
              </>
            )}
            {(filter?.category === "House" ||
              filter?.category === "Shop/Office") && (
              <>
                <h6>
                  <small>Furnishing</small>
                </h6>
                <div className="form-fields mb-2">
                  <select
                    onChange={handleChange}
                    value={filter?.furnishing}
                    className="form-select"
                    name="furnishing"
                  >
                    <option value="Select">Select</option>
                    <option value="Furnished">Furnished</option>
                    <option value="Non Furnish">Non Furnish</option>
                  </select>
                </div>
              </>
            )}
            <h6>
              <small>Posted By</small>
            </h6>
            <div className="form-fields mb-2">
              <select
                onChange={handleChange}
                value={filter?.postedBy}
                className="form-select"
                name="postedBy"
              >
                <option value="Select">Select</option>
                <option value="Owner">Owner</option>
                <option value="Dealer">Dealer</option>
                <option value="Builder">Builder</option>
              </select>
            </div>
            {filter?.type !== "" ||
            filter?.area !== "" ||
            filter?.category !== "" ||
            filter?.bedroom !== "" ||
            filter?.furnishing !== "" ||
            filter?.postedBy !== "" ? (
              <>
                <button
                  className="bb-btn w-100"
                  onClick={() => {
                    setShowFilter(!showFilter);
                    setFilterBackDrop(!filterBackDrop);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Search
                </button>
                <button
                  className="mt-2 bb-btn bg-danger w-100"
                  onClick={handleClearFilter}
                >
                  Clear Filter
                </button>
              </>
            ) : (
              <button
                className="bb-btn w-100"
                onClick={() => {
                  setShowFilter(!showFilter);
                  setFilterBackDrop(!filterBackDrop);
                }}
              >
                Search
              </button>
            )}
          </div>
        </div>

        {/* DESKTOP FILTER  */}
        <div className={`product-filter-container ${isSticky && "sticky"} `}>
          <div className="category">
            <div className="form-fields mb-2">
              <h6>
                <small>Location</small>
              </h6>
              <input
                type="text"
                list="areas"
                name="area"
                value={filter?.area}
                onChange={handleChange}
                className="form-control"
                placeholder="Choose Area"
              />
              <datalist id="areas" placeholder="">
                {bhiwandiAreas?.data?.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </datalist>
              <small className="text-danger">
                {error && "Please select area from the list"}
              </small>
            </div>
            <div className="form-fields mb-2">
              <h6>
                <small>Type</small>
              </h6>
              <select
                onChange={handleChange}
                value={filter?.type}
                className="form-select"
                name="type"
              >
                <option value="All">Select </option>
                <option value="Sell">Buy</option>
                <option value="Rent">Rent</option>
              </select>
            </div>
          </div>
          <div className="category">
            {/* <h6>Categories</h6> */}
            <div className="form-fields mb-2">
              <h6>
                <small>Category</small>
              </h6>
              <select
                onChange={handleChange}
                value={filter?.category}
                className="form-select"
                name="category"
              >
                <option value="select">Select</option>
                <option value="House">House</option>
                <option value="Shop/Office">Shop/Office</option>
                <option value="Plot/Land">Plot/Land</option>
              </select>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <h6>
              <small>Budget</small>
            </h6>
            <span
              style={{ cursor: "pointer" }}
              className="text-primary"
              onClick={() => setBudgetInput(!budgetInput)}
            >
              <small>{budgetInput ? "Hide" : "Enter Manually"}</small>
            </span>
          </div>
          <span>
            <b>
              ₹{budget[0]} - ₹{budget[1]}
            </b>
          </span>
          <Slider
            className={"slider"}
            value={budget}
            min={MIN}
            max={MAX}
            onChange={setBudget}
          />
          {budgetInput && (
            <div className={`budget-input-container`}>
              <input
                type="number"
                value={minBudgetInput}
                onChange={handleMinBudgetChange}
                className="budget-input"
                placeholder="min"
              />
              <input
                type="number"
                value={maxBudgetInput}
                onChange={handleMaxBudgetChange}
                className="budget-input"
                placeholder="max"
              />
            </div>
          )}
          <hr />
          <h6>
            <small>AREA SQFT</small>
          </h6>
          <span>
            <b>{sqft[0]}</b> <small>sqft</small> - <b>{sqft[1]}</b>{" "}
            <small>sqft</small>
          </span>
          <Slider
            className={"slider"}
            value={sqft}
            min={MINSQFT}
            max={MAXSQFT}
            onChange={setSqft}
          />
          <hr />
          {filter?.category === "House" && (
            <>
              <h6>
                <small>No. of Bedrooms</small>
              </h6>
              <div className="form-fields mb-2">
                <select
                  onChange={handleChange}
                  value={filter?.bedroom}
                  className="form-select"
                  name="bedroom"
                >
                  <option value="Select">Select</option>
                  <option value="1">1 BHK</option>
                  <option value="2">2 BHK</option>
                  <option value="3">3 BHK</option>
                  <option value="4">4 BHK</option>
                  <option value="5">5 BHK</option>
                  <option value="6">6 BHK</option>
                  <option value="7">7 BHK</option>
                  <option value="8">8 BHK</option>
                  <option value="9">9 BHK</option>
                  <option value="9">9 BHK</option>
                  <option value="10">10 BHK</option>
                </select>
              </div>
            </>
          )}
          {(filter?.category === "House" ||
            filter?.category === "Shop/Office") && (
            <>
              <h6>
                <small>Furnishing</small>
              </h6>
              <div className="form-fields mb-2">
                <select
                  onChange={handleChange}
                  value={filter?.furnishing}
                  className="form-select"
                  name="furnishing"
                >
                  <option value="Select">Select</option>
                  <option value="Furnished">Furnished</option>
                  <option value="Non Furnish">Non Furnish</option>
                </select>
              </div>
            </>
          )}
          <h6>
            <small>Posted By</small>
          </h6>
          <div className="form-fields mb-2">
            <select
              onChange={handleChange}
              value={filter?.postedBy}
              className="form-select"
              name="postedBy"
            >
              <option value="Select">Select</option>
              <option value="Owner">Owner</option>
              <option value="Dealer">Dealer</option>
              <option value="Builder">Builder</option>
            </select>
          </div>
          <button
            className="bg-danger bb-btn w-100"
            onClick={handleClearFilter}
          >
            Clear Filter
          </button>
        </div>
        {/* PROPERTIES  */}
        <div className="all-products-container">
          {currentItems?.map((item, index) => {
            return (
              <div
                key={index}
                className="bb-product"
                onClick={() => navigate(`/property-info/${item?.bbId}`)}
              >
                <div className="bb-product-img">
                  <img
                    src={`https://bhiwandibroker.com/${item?.images[0]}`}
                    alt=""
                  />
                </div>
                <div className="bb-product-content">
                  <h6 className="mt-0 mt-lg-3 mb-0">{item?.title}</h6>
                  <span className="text-muted">
                    {item?.category === "House"
                      ? item?.bedroom + " BHK Flat "
                      : item?.category === "Plot/Land"
                      ? item?.plotArea + " " + item?.plotAreaUnit + " Plot "
                      : item?.category === "Shop/Office"
                      ? item?.builtupArea + " sqft Shop/Office "
                      : ""}
                    on {item?.type === "Sell" ? "Sale" : "Rent"} in {item?.area}
                  </span>
                  {/* PRICE BEDROOM SQFT  */}
                  <div className="bb-product-price-sqft-bedrooms">
                    <div className="bb-price">
                      <span>
                        ₹{parseInt(item?.price, 10).toLocaleString("en-US")}
                        {item?.type === "Rent" && "/Month"}
                      </span>
                      <small>Price</small>
                    </div>
                    <div className="bb-sqft">
                      <span className="m-0">
                        {item?.category === "House"
                          ? item?.builtupArea + " " + "sqft"
                          : item?.category === "Shop/Office"
                          ? item?.builtupArea + " " + "sqft"
                          : item?.category === "Plot/Land"
                          ? item?.plotArea + " " + item?.plotAreaUnit
                          : ""}
                      </span>
                      <small>
                        {item?.category === "House"
                          ? "Builtup Area"
                          : item?.category === "Shop/Office"
                          ? "Builtup Area"
                          : item?.category === "Plot/Land"
                          ? "Plot Area"
                          : ""}
                      </small>
                    </div>
                    <div className="bb-bedrooms">
                      <span className="m-0">
                        {item?.category === "House"
                          ? item?.bedroom + " " + "BHK"
                          : item?.category === "Shop/Office"
                          ? "₹" + item?.maintenanceCost + "/Month"
                          : item?.category === "Plot/Land"
                          ? item?.plotOpenSides
                          : ""}
                      </span>
                      <small>
                        {item?.category === "House"
                          ? item?.pStatus
                          : item?.category === "Shop/Office"
                          ? "Maintenance"
                          : item?.category === "Plot/Land"
                          ? "Plot Open Sides"
                          : ""}
                      </small>
                    </div>
                  </div>
                  {/* DESC */}
                  {/* <small className="text-muted bb-highlights">
                      Highlights: <span>Furnished</span>{" "}
                      <span>Air Conditioner</span>
                    </small> */}
                  <small className="text-muted">{item?.about}</small>
                  <div className="bb-date-contact">
                    <span>
                      <small>
                        Posted by <b>{item?.postedBy}</b>
                      </small>
                    </span>
                    <span>
                      <small>
                        {new Date(item?.created).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </small>
                    </span>
                    <span>
                      <small>Interested</small>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          {/* Pagination component */}
          {filteredProperties?.length > 0 ? (
            <Pagination
              currentPage={currentPage}
              paginate={paginate}
              products={filteredProperties}
              itemsPerPage={itemsPerPage}
            />
          ) : (
            <NoResultFound />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ProductPage;
