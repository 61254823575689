import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import "./AddProperty.css";
import InfoIcon from "@mui/icons-material/Info";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { message } from "antd";
import axios from "axios";
import bhiwandiAreas from "../bhiwandiAreas";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";

const AddProperty = () => {
  const { user } = useSelector((state) => state.user);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formErr, setFormErr] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [form, setForm] = useState({
    bbId: "",
    title: "",
    about: "",
    type: "",
    category: "",
    price: "",
    area: "",
    address: "",
    email: user?.email,
    mobile: user?.mobile,
    bedroom: "",
    bathroom: "",
    furnishing: "",
    pStatus: "",
    postedBy: "",
    builtupArea: "",
    carpetArea: "",
    maintenance: "",
    maintenanceCost: "",
    totalFloors: "",
    floorNumber: "",
    parking: "",
    washroom: "",
    plotArea: "",
    plotAreaUnit: "sqft",
    plotLength: "",
    plotBreadth: "",
    plotOpenSides: "",
    plotConsDone: "",
  });

  //! STATES FOR NUMBERS
  const [priceNan, setPriceNan] = useState(false);
  const [builtupAreaNan, setBuiltupAreaNan] = useState(false);
  const [carpetAreaNan, setCarpetAreaNan] = useState(false);
  const [maintenanceNan, setMaintenanceNan] = useState(false);
  const [totalFloorNan, setTotalFloorNan] = useState(false);
  const [floorNumberNan, setFloorNumberNan] = useState(false);

  function generateRandomId() {
    const prefix = "bb";
    const numbers = Array.from({ length: 10 }, () =>
      Math.floor(Math.random() * 10)
    ).join("");
    setForm((prevForm) => ({
      ...prevForm,
      bbId: prefix + numbers,
    }));
  }

  useEffect(() => {
    generateRandomId();
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
    if (name === "area") {
      const isValidArea = bhiwandiAreas?.data?.some(
        (item) => item.name === value
      );
      if (isValidArea) {
        setError(false);
      } else {
        setError(true);
      }
    }
    if (name === "price") {
      if (isNaN(value)) {
        setPriceNan(true);
      } else {
        setPriceNan(false);
      }
    }
    if (name === "builtupArea") {
      if (isNaN(value)) {
        setBuiltupAreaNan(true);
      } else {
        setBuiltupAreaNan(false);
      }
    }
    if (name === "carpetArea") {
      if (isNaN(value)) {
        setCarpetAreaNan(true);
      } else {
        setCarpetAreaNan(false);
      }
    }
    if (name === "maintenanceCost") {
      if (isNaN(value)) {
        setMaintenanceNan(true);
      } else {
        setMaintenanceNan(false);
      }
    }
    if (name === "totalFloors") {
      if (isNaN(value)) {
        setTotalFloorNan(true);
      } else {
        setTotalFloorNan(false);
      }
    }
    if (name === "floorNumber") {
      if (isNaN(value)) {
        setFloorNumberNan(true);
      } else {
        setFloorNumberNan(false);
      }
    }
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    if (files.length > 8) {
      alert("You can only upload up to 8 files.");
      e.target.value = null;
      return;
    }
    const compressedImages = await Promise.all(
      Array.from(files).map(async (file) => {
        // Compress each image before uploading
        const compressedImage = await compressImage(file);
        return compressedImage;
      })
    );
    setSelectedFiles(compressedImages);
  };

  const compressImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 1, // Adjust the maximum size as needed
        maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
      };
      const compressedImage = await imageCompression(file, options);
      return compressedImage;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    const filesArray = Array.from(selectedFiles);
    if (selectedFiles.length === 0) {
      message.error("No photo is selected");
      return;
    }

    if (user === null) {
      return;
    }
    if (form?.category === "House") {
      if (
        form?.title === "" ||
        form?.about === "" ||
        form?.type === "" ||
        form?.category === "" ||
        form?.price === "" ||
        form?.area === "" ||
        form?.address === "" ||
        form?.bedroom === "" ||
        form?.bathroom === "" ||
        form?.furnishing === "" ||
        form?.pStatus === "" ||
        form?.postedBy === "" ||
        form?.builtupArea === "" ||
        form?.carpetArea === "" ||
        form?.maintenanceCost === "" ||
        form?.totalFloors === "" ||
        form?.floorNumber === "" ||
        form?.parking === ""
      ) {
        return setFormErr(true);
      }
    } else if (form?.category === "Shop/Office") {
      if (
        form?.title === "" ||
        form?.about === "" ||
        form?.type === "" ||
        form?.category === "" ||
        form?.price === "" ||
        form?.area === "" ||
        form?.address === "" ||
        form?.furnishing === "" ||
        form?.postedBy === "" ||
        form?.builtupArea === "" ||
        form?.carpetArea === "" ||
        form?.maintenanceCost === "" ||
        form?.parking === "" ||
        form?.washroom === ""
      ) {
        return setFormErr(true);
      }
    } else if (form?.category === "Plot/Land") {
      if (
        form?.title === "" ||
        form?.about === "" ||
        form?.type === "" ||
        form?.category === "" ||
        form?.price === "" ||
        form?.area === "" ||
        form?.address === "" ||
        form?.postedBy === "" ||
        form?.plotArea === "" ||
        form?.plotLength === "" ||
        form?.plotBreadth === "" ||
        form?.plotOpenSides === "" ||
        form?.plotConsDone === ""
      ) {
        return setFormErr(true);
      }
    }

    if (
      priceNan ||
      builtupAreaNan ||
      carpetAreaNan ||
      maintenanceNan ||
      totalFloorNan ||
      floorNumberNan
    ) {
      return;
    }

    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];
      formData.append("images", file);
    }
    setLoading(true);
    try {
      const res = await axios.post("/api/property/add-property", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setFormErr(false);
        setForm({
          title: "",
          about: "",
          type: "",
          category: "",
          price: "",
          area: "",
          address: "",
          email: "",
          mobile: "",
          bedroom: "",
          bathroom: "",
          furnishing: "",
          pStatus: "",
          postedBy: "",
          builtupArea: "",
          carpetArea: "",
          maintenance: "",
          maintenanceCost: "",
          totalFloors: "",
          floorNumber: "",
          parking: "",
          washroom: "",
          plotArea: "",
          plotAreaUnit: "sqft",
          plotLength: "",
          plotBreadth: "",
          plotOpenSides: "",
          plotConsDone: "",
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      email: user?.email,
      mobile: user?.mobile,
    }));
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">ADD PROPERTY</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div className="basic-info-property">
            <div>
              <CollectionsIcon className="me-1" />
              Property Images
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <label htmlFor="" className="form-lable mb-1">
                  <small>You can upload upto 8 images</small>
                </label>
                <input
                  aria-label="Select Image"
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  name="image"
                  required
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="form-control"
                />
                {/* <input type="file" name="images" className="form-control" /> */}
                <small className="text-danger">
                  Note: Images size should be less than 1 MB
                </small>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <InfoIcon className="me-1" />
              Basic Information
            </div>
            <hr />
            <div className="row">
              <div className="col-12 form-fields mb-2">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  value={form?.title}
                  onChange={handleChange}
                  placeholder="Enter property title"
                />
                <small className="text-danger">
                  {formErr && form?.title === "" && "Enter property title"}
                </small>
              </div>
              <div className="col-12 form-fields mb-2">
                <textarea
                  name="about"
                  cols="30"
                  rows="5"
                  placeholder="Write something about your property"
                  className="form-control"
                  value={form?.about}
                  onChange={handleChange}
                ></textarea>
                <small className="text-danger">
                  {formErr && form?.about === "" && "Enter description"}
                </small>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Type</small>
                  </label>
                  <select
                    value={form?.type}
                    onChange={handleChange}
                    name="type"
                    className="form-select"
                  >
                    <option value="">Select Type</option>
                    <option value="Sell">Sell</option>
                    <option value="Rent">Rent</option>
                  </select>
                  <small className="text-danger">
                    {formErr && form?.type === "" && "Select type"}
                  </small>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Category</small>
                  </label>
                  <select
                    name="category"
                    className="form-select"
                    value={form?.category}
                    onChange={handleChange}
                  >
                    <option value="">Select Category</option>
                    <option value="House">House</option>
                    <option value="Shop/Office">Shop/Office</option>
                    <option value="Plot/Land">Plot/Land</option>
                    {/* <option value="Villa">Villa</option> */}
                  </select>
                  <small className="text-danger">
                    {formErr && form?.category === "" && "Select category"}
                  </small>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    {form?.type === "Rent" ? "Monthly Rent" : "Price"}
                  </label>
                  <input
                    name="price"
                    placeholder="Enter price"
                    type="text"
                    className="form-control"
                    value={form?.price}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    {formErr && form?.price === "" && "Enter price"}
                  </small>
                  <small className="text-danger">
                    {priceNan && "Enter only numbers"}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <EditLocationAltIcon className="me-1" />
              Location / Contacts
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Area</small>
                  </label>
                  <input
                    type="text"
                    list="areas"
                    name="area"
                    id="area"
                    value={form?.area}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Select Area"
                  />
                  <datalist id="areas" placeholder="">
                    {bhiwandiAreas?.data?.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </datalist>
                  <small className="text-danger">
                    {formErr && form?.area === ""
                      ? "Enter title"
                      : error && "Select from the list only"}
                  </small>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-8">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Address</small>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter full address"
                    className="form-control"
                    value={form?.address}
                    onChange={handleChange}
                  />
                  <small className="text-danger">
                    {formErr && form?.address === "" && "Enter full address"}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <HomeWorkIcon className="me-1" />
              Property Details
            </div>
            <hr />
            <div className="row">
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Bedrooms</small>
                        </label>
                        <select
                          name="bedroom"
                          className="form-select"
                          value={form?.bedroom}
                          onChange={handleChange}
                        >
                          <option value="">Select Bedrooms</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>

                        <small className="text-danger">
                          {formErr && form?.bedroom === "" && "Select bedroom"}
                        </small>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Bathrooms</small>
                        </label>
                        <select
                          name="bathroom"
                          className="form-select"
                          value={form?.bathroom}
                          onChange={handleChange}
                        >
                          <option value="">Select Bathrooms</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                        <small className="text-danger">
                          {formErr &&
                            form?.bathroom === "" &&
                            "Select bathroom"}
                        </small>
                      </div>
                    </div>
                  </>
                )}
              {form?.category !== "Plot/Land" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Furnishing</small>
                    </label>
                    <select
                      name="furnishing"
                      className="form-select"
                      value={form?.furnishing}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="Furnished">Furnished</option>
                      <option value="Non Furnish">Non Furnish</option>
                    </select>
                    <small className="text-danger">
                      {formErr &&
                        form?.furnishing === "" &&
                        "Select furnishing"}
                    </small>
                  </div>
                </div>
              )}
              {/* PROPERTY STATUS  */}
              {/* {form?.type === "Sell" && form?.category === "Shop/Office" && (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Property Status</small>
                      </label>
                      <select
                        name="pStatus"
                        className="form-select"
                        value={form?.pStatus}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="New Launch">New Launch</option>
                        <option value="Ready to Move">Ready to Move</option>
                        <option value="Under Construction">
                          Under Construction
                        </option>
                      </select>
                      <small className="text-danger">
                        {formErr && form?.pStatus === "" && "Select status"}
                      </small>
                    </div>
                  </div>
                )} */}
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Property Status</small>
                      </label>
                      <select
                        name="pStatus"
                        className="form-select"
                        value={form?.pStatus}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="New Launch">New Launch</option>
                        <option value="Ready to Move">Ready to Move</option>
                        <option value="Under Construction">
                          Under Construction
                        </option>
                      </select>
                      <small className="text-danger">
                        {formErr && form?.pStatus === "" && "Select status"}
                      </small>
                    </div>
                  </div>
                )}
              {/* POSTED BY  */}
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Posted By</small>
                  </label>
                  <select
                    name="postedBy"
                    className="form-select"
                    value={form?.postedBy}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Builder">Builder</option>
                    <option value="Dealer">Dealer</option>
                    <option value="Owner">Owner</option>
                  </select>
                  <small className="text-danger">
                    {formErr && form?.postedBy === "" && "Select posted by"}
                  </small>
                </div>
              </div>
              {/* BUILT UP AREA AND CARPET AREA  */}
              {form?.category !== "Plot/Land" && (
                <>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Built Up Area (ft²)</small>
                      </label>
                      <input
                        type="text"
                        name="builtupArea"
                        className="form-control"
                        placeholder="Enter in sqft"
                        value={form?.builtupArea}
                        onChange={handleChange}
                      />
                      <small className="text-danger">
                        {formErr &&
                          form?.builtupArea === "" &&
                          "Enter builtup area"}
                      </small>
                      <small className="text-danger">
                        {builtupAreaNan && "Enter only numbers"}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Carpet Area (ft²)</small>
                      </label>
                      <input
                        type="text"
                        name="carpetArea"
                        className="form-control"
                        placeholder="Enter in sqft"
                        value={form?.carpetArea}
                        onChange={handleChange}
                      />
                      <small className="text-danger">
                        {formErr &&
                          form?.carpetArea === "" &&
                          "Enter carpet area"}
                      </small>
                      <small className="text-danger">
                        {carpetAreaNan && "Enter only numbers"}
                      </small>
                    </div>
                  </div>
                </>
              )}
              {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Maintenance</small>
                    </label>
                    <select
                      className="form-select"
                      name="maintenance"
                      value={form?.maintenance}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div> */}
              {/* MAINTENANCE COST  */}

              {form?.category !== "Plot/Land" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Maintenance Cost Monthly </small>
                    </label>
                    <input
                      type="text"
                      name="maintenanceCost"
                      className="form-control"
                      placeholder="Enter maintenance cost"
                      value={form?.maintenanceCost}
                      onChange={handleChange}
                    />
                    <small className="text-danger">
                      {formErr &&
                        form?.maintenanceCost === "" &&
                        "Enter maintenance cost"}
                    </small>
                    <small className="text-danger">
                      {maintenanceNan && "Enter only numbers"}
                    </small>
                  </div>
                </div>
              )}
              {/* TOTAL FLOOR  */}
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Total Floors</small>
                      </label>
                      <input
                        type="text"
                        name="totalFloors"
                        className="form-control"
                        placeholder="Enter total floors"
                        value={form?.totalFloors}
                        onChange={handleChange}
                      />
                      <small className="text-danger">
                        {formErr &&
                          form?.totalFloors === "" &&
                          "Enter total floors"}
                      </small>
                      <small className="text-danger">
                        {totalFloorNan && "Enter only numbers"}
                      </small>
                    </div>
                  </div>
                )}
              {/* FLOOR NO */}
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Floor No</small>
                      </label>
                      <input
                        type="text"
                        name="floorNumber"
                        className="form-control"
                        placeholder="House floor number"
                        value={form?.floorNumber}
                        onChange={handleChange}
                      />
                      <small className="text-danger">
                        {formErr &&
                          form?.floorNumber === "" &&
                          "Enter floor number"}
                      </small>
                      <small className="text-danger">
                        {floorNumberNan && "Enter only numbers"}
                      </small>
                    </div>
                  </div>
                )}
              {/* PARKING  */}
              {form?.category !== "Plot/Land" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Parking</small>
                    </label>
                    <select
                      name="parking"
                      className="form-select"
                      value={form?.parking}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3+">3+</option>
                    </select>
                    <small className="text-danger">
                      {formErr && form?.parking === "" && "Select parking"}
                    </small>
                  </div>
                </div>
              )}
              {/* WASHROOM  */}
              {form?.category === "Shop/Office" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Washroom</small>
                    </label>
                    <select
                      name="washroom"
                      className="form-select"
                      value={form?.washroom}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
              )}
              {form?.category === "Plot/Land" && (
                <>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Plot Area</small>
                      </label>
                      <div className="parent-input">
                        <input
                          type="text"
                          name="plotArea"
                          className="form-control"
                          placeholder="Enter plot area"
                          value={form?.plotArea}
                          onChange={handleChange}
                        />
                        <select
                          className="plotAreaUnit"
                          name="plotAreaUnit"
                          onChange={handleChange}
                          value={form?.plotAreaUnit}
                        >
                          <option value="sqft">sqft</option>
                          <option value="sq.yards">sq.yards</option>
                          <option value="sq.m">sq.m</option>
                          <option value="acres">acres</option>
                          <option value="guntah">gutah</option>
                          <option value="marla">marla</option>
                          <option value="cent">cent</option>
                          <option value="bigah">bigah</option>
                          <option value="ground">ground</option>
                          <option value="biswa">biswa</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Length</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="plotLength"
                        onChange={handleChange}
                        value={form?.plotLength}
                        placeholder={`Length in ${form?.plotAreaUnit}`}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Breadth</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="plotBreadth"
                        onChange={handleChange}
                        value={form?.plotBreadth}
                        placeholder={`Breadth in ${form?.plotAreaUnit}`}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>No. of open sides</small>
                      </label>
                      <select
                        name="plotOpenSides"
                        className="form-select"
                        value={form?.plotOpenSides}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="3+">3+</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Any Construction done on this property?</small>
                      </label>
                      <select
                        name="plotConsDone"
                        className="form-select"
                        value={form?.plotConsDone}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
            <button
              disabled={error}
              onClick={handleSubmit}
              className="bb-btn w-100 mt-3"
            >
              Add Property
            </button>
          </div>
          <div className="contact-us">
            <span>
              Facing Problem while posting a property? Contact our team support{" "}
              <Link
                target="_blank"
                className="text-decoration-underline"
                to="https://wa.me/919284263003"
              >
                Click Here
              </Link>
            </span>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default AddProperty;
