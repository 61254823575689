import React, { useState } from "react";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CallIcon from "@mui/icons-material/Call";
import DraftsIcon from "@mui/icons-material/Drafts";
import axios from "axios";
import IMAGES from "../../img/image";
import "../Footer/Footer.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/user/subscribe", { email });
      if (res.data.success) {
        setMsg(true);
        setLoading(false);
      } else {
        setMsg(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setMsg(false);
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="footer-container">
        <Link to="tel:919284263003">
          <div className="support">
            <span>Need Support?</span>
            <SupportAgentIcon className="icon" />
          </div>
        </Link>
        {/* <h2 className="text-center mb-4 mb-lg-5">Contact Us</h2> */}
        <img src={IMAGES.logo} alt="" />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="contact-info">
              <FmdGoodIcon className="icon me-2" />
              <div className="contact-info-content">
                <h5 className="m-0">Find Us:</h5>
                <span>
                  <small>Bhiwandi, Maharashtra, India</small>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="contact-info">
              <CallIcon className="icon me-2" />
              <div className="contact-info-content">
                <h5 className="m-0">Call Us:</h5>
                <Link to="tel:9284263003">
                  <span>
                    <>Ansari Ashir</>
                    <br />
                    <>+91 9284263003</>
                  </span>
                </Link>
                <br />
                <Link to="tel:8390506870">
                  <span>
                    <>Sayeed Ahmed</>
                    <br />
                    <>+91 8390506870 </>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="contact-info">
              <DraftsIcon className="icon me-2" />
              <div className="contact-info-content">
                <h5 className="m-0">Mail Us:</h5>
                <span>
                  <small>bhiwandibroker@gmail.com</small>
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-lg-5" />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mt-4 mt-lg-0 mb-4">
            <div className="contact-info">
              <div className="contact-info-content">
                <h5 className="m-0 mb-3">Follow Us</h5>
                <span>
                  <small>We're social! Find us all around!</small>
                </span>
                <div className="d-flex mt-2">
                  <Link to="https://wa.me/919284263003" target="_blank">
                    <WhatsAppIcon className="icon fs-2 me-2" />
                  </Link>
                  <Link to="https://wa.me/918390506870" target="_blank">
                    <WhatsAppIcon className="icon fs-2" />
                  </Link>
                  <Link
                    to="https://instagram.com/bhiwandibroker"
                    target="_blank"
                  >
                    <InstagramIcon className="icon mx-2 fs-2" />
                  </Link>
                  <Link to="mailto:bhiwandibroker@gmail.com" target="_blank">
                    <EmailIcon className="icon fs-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="row">
              <div className="col-6">
                <div className="contact-info">
                  <div className="contact-info-content">
                    <h5 className="m-0 mb-3">Quick Links</h5>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/properties">Properties</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="contact-info">
                  <div className="contact-info-content">
                    <h5 className="m-0 mb-3">...</h5>
                    <ul>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      {/* <li>
                        <Link to="/disclaimer">Disclaimer</Link>
                      </li> */}
                      <li>
                        <Link to="/terms-and-conditions">Our Terms</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ's</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="contact-info">
              <div className="contact-info-content">
                <h5 className="m-0 mb-3">Subscribe</h5>
                <span>
                  <small>
                    Don't miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </small>
                </span>
                <form onSubmit={handleSubmit}>
                  <div className="mt-2">
                    {msg ? (
                      <div className="text-success subcribe-success">
                        You have been successfully subcribed!
                      </div>
                    ) : (
                      <div className="form-fields">
                        <input
                          placeholder="Email Address"
                          type="email"
                          className="text-dark w-100"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <button className="brown-btn bb-btn w-100 mt-2">
                          {loading && (
                            <div
                              class="spinner-grow spinner-grow-sm me-2 text-warning"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          )}
                          Subcribe
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
