import React from "react";
import "./ExploreCity.css";
import IMAGES from "../img/image";
import Slider from "react-slick";
import bg from "../img/home/blog5.webp";
import { useNavigate } from "react-router-dom";

const ExploreCity = () => {
  const navigate = useNavigate();
  const location = [
    "Anjurphata",
    "Ashok Nagar",
    // "Bhiwandi Nizampur",
    // "Chavindra Naka",
    "Dhamankar Naka",
    "Gaibi Nagar",
    // "Gopal Nagar",
    "Kalyan Naka",
    // "Kamatghar",
    // "Kaneri",
    "Millat Nagar",
    "Naigaon",
    "Nizampura",
    "Padgha",
    "Sai Nagar",
    "Vanjar Patti Naka",
    "Zakaat Naka",
  ];

  var settings = {
    dots: false,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="explore-city-container">
      <div className="explore-city-left">
        <h5>Explore Best Cities</h5>
        <p>
          <small>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aperiam
            saepe magnam nisi at animi eaque iure porro aut unde! Id.
          </small>
        </p>
        <img className="map-img" src={IMAGES.map} alt="" />
      </div>
      <div className="explore-city-right">
        <Slider {...settings}>
          {location?.map((item, index) => {
            return (
              <div className={`explore-city ${index % 2 && "diff"}`}>
                {/* <img src={IMAGES.city} alt="" /> */}
                <div className="explore-city-content">
                  <h4>{item}</h4>
                  <p>
                    <small>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Explicabo, aspernatur!
                    </small>
                  </p>
                  <button
                    onClick={() => navigate(`/properties/${item}`)}
                    className="bb-btn"
                  >
                    Explore
                  </button>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default ExploreCity;
