import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import CheckIcon from "@mui/icons-material/Check";
import IMAGES from "../img/image";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { discount } = useSelector((state) => state.discount);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [error, setError] = useState(false);
  const [total, setTotal] = useState(0);
  const [location, setLocation] = useState(null);
  const [addressSelected, setAddressSelected] = useState(0);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [placeOrder, setPlaceOrder] = useState(false);

  // shipping n coupon
  const [shippingCharge, setShippingCharge] = useState(0);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const calculateTotal = () => {
    const newTotal = cart.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setTotal(newTotal);
  };

  const handleSaveAddress = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/update-user", {
        email: user?.email,
        address: location,
      });
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        setAddNewAddress(!addNewAddress);
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOrderId = () => {
    const numbers = "0123456789"; // 10 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = "MBH" + randomNumbers.join("");
    setOrderId(orderId);
  };

  const handleChange = (e) => {
    setLocation({ ...location, [e.target.name]: e.target.value });
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (user) {
      setLocation(user?.address[addressSelected]);
    }
  }, [addressSelected]);

  useEffect(() => {
    calculateTotal();
    getUserData();
    generateOrderId();
  }, [cart]);

  const fetchShippingCharge = async () => {
    try {
      const res = await axios.get("/api/admin/get-shipping-charge");
      if (res.data.success) {
        setShippingCharge(res.data.data[0].shippingCharge);
        setData(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching shipping charge:", error);
    }
  };

  useEffect(() => {
    generateOrderId();
    fetchShippingCharge();
  }, []);

  const orderData = {
    amount: total + shippingCharge - (discount && discount),
    name: (user && user?.address[0]?.fname) || location?.fname,
    email: (user && user?.address[0]?.email) || location?.email,
    product: cart[0]?.name,
    mobile: (user && user?.address[0]?.mobile) || location?.mobile,
    address: (user && user?.address[0]?.city) || location?.city,
    callback_url: "/api/payment/payment-callback",
    cancel: "/api/payment/payment-cancel",
  };

  const paymentHandler = async (e) => {
    if (!user) {
      if (
        !location?.fname ||
        !location?.lname ||
        !location?.email ||
        !location?.mobile ||
        !location?.saddress ||
        !location?.baddress ||
        !location?.city ||
        !location?.state ||
        !location?.pincode
      ) {
        return setError(true);
      }
    }
    const response = await fetch("/api/payment/order", {
      method: "POST",
      body: JSON.stringify(orderData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    var options = {
      key: "rzp_live_4EwnsjZjWv6uer",
      amount: order.amount,
      currency: order.currency,
      name: "Mobi Haven",
      description: "Mobi Haven Transaction",
      image: `${IMAGES.mobi}`,
      order_id: order.id,
      handler: async function (response) {
        const body = {
          ...response,
        };
        const validateRes = await fetch("api/payment/validate", {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonRes = await validateRes.json();
        if (jsonRes.success) {
          try {
            const res = await axios.post("/api/order/place-order", {
              email: user?.email || location?.email,
              total: total + shippingCharge - (discount && discount),
              subTotal: total,
              user: localStorage.getItem("token") ? user : null,
              shipping: shippingCharge,
              username:
                user?.fname + user?.lname || location?.fname + location?.lname,
              cart,
              discount,
              location,
              orderId,
            });
            if (res.data.success) {
              const paymentObject = {
                name: orderData.name,
                email: orderData.email,
                mobile: orderData.mobile,
                amount: orderData.amount,
                orderId: orderId,
              };
              const payment = await axios.post(
                "/api/payment/save-payment",
                paymentObject
              );
              dispatch(setUser(res.data.data));
              localStorage.removeItem("cart");
              if (!localStorage.getItem("token")) {
                localStorage.setItem("token", res.data.token);
              }
              setOrderSuccess(true);
              getUserData();
            } else {
              message.error(res.data.message);
            }
          } catch (error) {
            console.log(error);
          }
        }
      },
      prefill: {
        name: `${
          user?.fname + " " + user?.lname ||
          location?.fname + " " + location?.lname
        }`, //your customer's name
        email: `${user?.email || location?.email}`,
        contact: `${location?.mobile}`,
      },
      notes: {
        address: "MOBI HAVEN",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  return (
    <Layout>
      {orderSuccess ? (
        <div className="order-success-container">
          <CheckIcon className="icon" />
          <span className="text-muted">Hey! Ansari Ashir</span>
          <h4 className="my-1">Thank you for ordering!</h4>
          <span className="text-muted text-center">
            We'll send you a shipping confirmation email <br /> as soon as your
            order ships.
          </span>
          <button
            onClick={() => {
              navigate("/user-dashboard");
              setOrderSuccess(false);
            }}
            className="add-to-cart-btn"
          >
            Check Order Status
          </button>
        </div>
      ) : (
        <div className="container checkout-container">
          <div className="customer-form">
            {!user && (
              <h5>
                Already a customer? <Link to="/login">Login</Link>
              </h5>
            )}
            {user && user?.address?.length > 0 && <h3>Your Saved Address</h3>}
            {user &&
              user?.address?.map((add, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setAddressSelected(index)}
                    className={`sv-address ${
                      addressSelected === index && "active"
                    }`}
                  >
                    {add?.fname} {add?.lname}, {add?.saddress}, {add?.baddress},{" "}
                    {add?.mobile}, {add?.city}, {add?.state}, {add?.pincode}
                    <div
                      className={`selected ${
                        addressSelected === index && "ac"
                      }`}
                    >
                      selected
                    </div>
                  </div>
                );
              })}
            {user && (
              <button
                onClick={() => setAddNewAddress(!addNewAddress)}
                className="add-to-cart-btn mb-3"
              >
                Add New Address
              </button>
            )}
            {!user ? (
              <>
                <div className="row">
                  <div className="col-6">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        name="fname"
                      />
                      {error && !location?.fname && (
                        <span className="text-danger">
                          <small>Enter First Name</small>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                      />
                      {error && !location?.lname && (
                        <span className="text-danger">
                          <small>Enter First Name</small>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="Enter email"
                    type="text"
                    name="email"
                  />
                  {error && !location?.email && (
                    <span className="text-danger">
                      <small>Enter First Name</small>
                    </span>
                  )}
                </div>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="Enter mobile number"
                    name="mobile"
                    type="text"
                  />
                  {error && !location?.mobile && (
                    <span className="text-danger">
                      <small>Enter First Name</small>
                    </span>
                  )}
                </div>
                <h4 className="my-3">Delivery</h4>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="street address"
                    name="saddress"
                    type="text"
                  />
                  {error && !location?.saddress && (
                    <span className="text-danger">
                      <small>Enter First Name</small>
                    </span>
                  )}
                </div>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="Appartment, suite, unit, etc"
                    name="baddress"
                    type="text"
                  />
                  {error && !location?.baddress && (
                    <span className="text-danger">
                      <small>Enter First Name</small>
                    </span>
                  )}
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="City"
                        name="city"
                        type="text"
                      />
                      {error && !location?.city && (
                        <span className="text-danger">
                          <small>Enter First Name</small>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="State"
                        name="state"
                        type="text"
                      />
                      {error && !location?.state && (
                        <span className="text-danger">
                          <small>Enter First Name</small>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="Pin code"
                        name="pincode"
                        type="text"
                      />
                      {error && !location?.pincode && (
                        <span className="text-danger">
                          <small>Enter First Name</small>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : addNewAddress ? (
              <>
                <div className="row">
                  <div className="col-6">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        name="fname"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="Enter email"
                    type="text"
                    name="email"
                  />
                </div>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="Enter mobile number"
                    name="mobile"
                    type="text"
                  />
                </div>
                <h4 className="my-3">Delivery</h4>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="street address"
                    name="saddress"
                    type="text"
                  />
                </div>
                <div className="form-fields mb-2">
                  <input
                    onChange={handleChange}
                    style={{ padding: "8px" }}
                    className="form-control"
                    placeholder="Appartment, suite, unit, etc"
                    name="baddress"
                    type="text"
                  />
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="City"
                        name="city"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="State"
                        name="state"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-fields mb-2">
                      <input
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="Pin code"
                        name="pincode"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleSaveAddress}
                  className="w-50 add-to-cart-btn"
                >
                  Save this Address
                </button>
                <button
                  onClick={() => setAddNewAddress(!addNewAddress)}
                  className="w-50 add-to-cart-btn bg-dark text-white"
                >
                  cancel
                </button>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="checkout-product-details">
            <div className="checkout-item-container">
              <div className="d-flex justify-content-between">
                <span>Product</span>
                <span>Subtotol</span>
              </div>
              <hr />
              {cart &&
                cart.map((item, index) => {
                  return (
                    <div key={index} className="checkout-item">
                      <span>
                        {item.name} x {item.quantity}
                      </span>
                      <span>{item.quantity * item.price}</span>
                    </div>
                  );
                })}
              <div className="checkout-item">
                <span>Subtotal</span>
                <span>{total}</span>
              </div>
              <hr />
              <div className="checkout-item">
                <span>Delivery Charge</span>
                {shippingCharge === 0 ? (
                  <span>Free</span>
                ) : (
                  <span>+ {shippingCharge}</span>
                )}
              </div>
              {discount !== null && (
                <div className="checkout-item">
                  <span>Discount</span>
                  <span>- {discount}</span>
                </div>
              )}
              <hr />
              <div className="checkout-item">
                <span>
                  <b>Total</b>
                </span>
                <span>
                  <b>Rs. {total + shippingCharge - (discount && discount)}</b>
                </span>
              </div>
            </div>
            <button
              onClick={paymentHandler}
              className={`w-100 add-to-cart-btn`}
            >
              Place Order
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Checkout;
