import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HelpIcon from "@mui/icons-material/Help";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import EmailIcon from "@mui/icons-material/Email";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import InventoryIcon from "@mui/icons-material/Inventory";
import PercentIcon from "@mui/icons-material/Percent";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { Link } from "react-router-dom";
import "./AdminSidebar.css";

const AdminSidebar = () => {
  return (
    <div className="admin-sidebar-container">
      <span>
        <small>MAIN</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-dashboard">
            <HomeIcon className="me-2" />
            Dashboard
          </Link>
        </li>
      </ul>
      <span>
        <small>LISTS</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-properties">
            <InventoryIcon className="me-2" />
            Properties
          </Link>
        </li>
        <li>
          <Link to="/admin-users">
            <GroupIcon className="me-2" />
            Users
          </Link>
        </li>
        <li>
          <Link to="/admin-leads">
            <AutoGraphIcon className="me-2" />
            Leads
          </Link>
        </li>
        <li>
          <Link to="/admin-queries">
            <HelpIcon className="me-2" />
            Queries
          </Link>
        </li>
        <li>
          <Link to="/admin-subscribers">
            <SupervisedUserCircleIcon className="me-2" />
            Subcribers
          </Link>
        </li>
        <li>
          <Link to="/admin-bulk-email">
            <EmailIcon className="me-2" />
            Bulk Email
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
