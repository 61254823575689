import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    username: "",
    email: "",
    mobile: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (name === "mobile") {
      if (isNaN(value)) {
        setErr(true);
      } else if (value.length > 10) {
        setErr(true);
      } else if (value.length < 10) {
        setErr(true);
      } else {
        setErr(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      form?.username === "" ||
      form?.email === "" ||
      form?.mobile === "" ||
      form?.password === ""
    ) {
      return setError(true);
    }
    if (err) {
      return;
    }
    try {
      setLoading(true);
      const res = await axios.post("/api/user/register", form);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/login");
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="container-fluid register-container bg-white">
        <div className="row text-center">
          <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6">
            <form className="register-form" onSubmit={handleSubmit}>
              <h1>Create Account</h1>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.username}
                  name="username"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                />
                <small className="d-block mt-1 text-danger text-start">
                  {error && form?.username === "" && "Enter name"}
                </small>
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.email}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
                <small className="d-block mt-1 text-danger text-start">
                  {error && form?.email === "" && "Enter email"}
                </small>
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.mobile}
                  name="mobile"
                  type="text"
                  className="form-control"
                  placeholder="Mobile"
                />
                <small className="text-start d-block text-danger">
                  {error && form?.mobile === ""
                    ? "Enter mobile"
                    : err && isNaN(form?.mobile)
                    ? "Enter only numbers"
                    : err && form?.mobile.length > 10
                    ? "Enter only 10 digits "
                    : err && form?.mobile.length < 10
                    ? "Enter 10 digits"
                    : ""}
                </small>
              </div>
              <div className="form-fields mb-3">
                <input
                  onChange={handleChange}
                  value={form?.password}
                  name="password"
                  type="text"
                  className="form-control"
                  placeholder="Password"
                />
                <small className="d-block mt-1 text-danger text-start">
                  {error && form?.password === "" && "Enter password"}
                </small>
              </div>
              <button className="register-btn">
                {loading ? "Creating" : "Create Now"}
                {loading && (
                  <div class="ms-2 spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
              <div className="forgot-pass d-flex justify-content-between">
                <h6 className="text-center my-2">
                  Already a Customer? <Link to="/login">Click here</Link>
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
