import React, { useEffect, useState } from "react";
import "./MyListings.css";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import IMAGES from "../img/image";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";
import NoResultFound from "../components/UI/NoResultFound";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useNavigate } from "react-router-dom";

const MyListings = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [properties, setProperties] = useState(null);

  async function getAllUserProperties() {
    try {
      const res = await axios.post(
        "/api/property/get-all-user-property",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProperties(res.data.data.reverse());
      } else {
        setProperties(null);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDelete(e, id) {
    e.stopPropagation();
    e.preventDefault();
    const isConfirmed = window.confirm("Are you sure to delete?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(`/api/property/delete-property/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (res.data.success) {
          message.success(res.data.message);
          getAllUserProperties();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function handleEdit(e, bbId) {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/edit-property/${bbId}`);
  }

  useEffect(() => {
    getAllUserProperties();
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">My LISTINGS</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div className="my-listings-container">
            <div className="my-listed-product-container">
              {properties && properties.length > 0 ? (
                properties.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="my-listed-product"
                      onClick={() => navigate(`/property-info/${item?.bbId}`)}
                    >
                      <div className="my-listed-product-img">
                        <span
                          className={`p-tag ${
                            item.verified && "verified px-2"
                          }`}
                        >
                          {item.verified ? "verified" : "Under Verification"}
                        </span>
                        <span className="camera-tag">
                          {item?.images?.length}
                          <CameraAltIcon className="icon ms-1" />
                        </span>
                        <img src={item?.images[0]} alt="" />
                      </div>
                      <div className="my-listed-product-content">
                        <div className="my-listing-name">
                          <h6>{item?.title}</h6>
                          {item?.category === "House" ? (
                            <p className="m-0">
                              {item?.bedroom} BHK on{" "}
                              {item?.type === "Rent" ? "Rent" : "Sale"} in{" "}
                              {item?.area}
                            </p>
                          ) : item?.category === "Plot/Land" ? (
                            <p className="m-0">
                              {item?.plotArea} {item?.plotAreaUnit} Plot on{" "}
                              {item?.type === "Rent" ? "Rent" : "Sale"} in{" "}
                              {item?.area}
                            </p>
                          ) : item?.category === "Shop/Office" ? (
                            <p className="m-0">
                              Shop on {item?.type === "Rent" ? "Rent" : "Sale"}{" "}
                              in {item?.area}
                            </p>
                          ) : (
                            ""
                          )}
                          <span>
                            <small>{item?.about}</small>
                          </span>
                          <h6 className="mt-2 mb-0">Price: {item?.price}</h6>
                        </div>
                        <div className="my-listing-action">
                          {/* <div className="listing-views">
                            <VisibilityIcon className="icon me-1" />
                            <span>
                              <small>1000</small>
                            </span>
                          </div> */}
                          <div className="listing-edit">
                            <EditIcon
                              className="icon"
                              onClick={(e) => handleEdit(e, item?.bbId)}
                            />
                          </div>
                          <div className="listing-delete">
                            <DeleteOutlineIcon
                              onClick={(e) => handleDelete(e, item?._id)}
                              className="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoResultFound />
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default MyListings;
