import React from "react";
import "./Faq.css";
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <React.Fragment>
      <div className="faq-container">
        <h2 className="faq">Frequently Asked Question</h2>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                How does Bhiwandi Broker Platform work?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Bhiwandi Broker Platform connects buyers and sellers in the real
                estate market. Users can browse listings, connect with brokers,
                and complete transactions seamlessly.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                What sets Bhiwandi Broker Platform apart from others?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Our platform prioritizes user-friendly interfaces, innovative
                tools, and transparent processes, providing a unique and
                efficient real estate experience.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                How can I list my property on Bhiwandi Broker Platform?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Listing your property is simple. Create an account, provide
                property details, upload images, and connect with our team for
                verification and approval.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                What information is available in property listings?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Listings include comprehensive details such as property
                specifications, pricing, images, and, where available,
                historical data and trends for informed decision-making.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                Are there any fees associated with using Bhiwandi Broker
                Platform?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Browsing and basic features are free. However, if you want to
                deal with any property from bhiwandi broker the standard charges
                are applicable. For more information you can{" "}
                <Link to="/contact">contact us</Link>
              </div>
            </div>
          </div>
          <span>
            Feel free to <Link to="/contact">contact us</Link> if you have more
            questions or need assistance! Your satisfaction is our priority.
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
