import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Dashboard from "./user/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./admin/AdminDashboard";
import AdminUsers from "./admin/AdminUsers";
import EditUser from "./admin/EditUser";
import AdminPayments from "./admin/AdminPayments";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AdminBulkEmail from "./admin/AdminBulkEmail";
import AdminLogin from "./admin/AdminLogin";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";
import PhoneSkins from "./pages/PhoneSkins";
import Search from "./pages/Search";
import AdminOrder from "./admin/AdminOrder";
import ProductInfo from "./pages/ProductInfo";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import TrackOrder from "./pages/TrackOrder";
import Orders from "./user/Orders";
import Address from "./user/Address";
import Account from "./user/Account";
import ViewOrder from "./user/ViewOrder";
import AdminViewOrder from "./admin/AdminViewOrder";
import PhotoUpload from "./admin/PhotoUpload";
import AdminQueries from "./admin/AdminQueries";
import Shipping from "./pages/Shipping";
import TermsAndConditions from "./pages/Terms";
import Subscribers from "./admin/Subscribers";
import AdminRoutes from "./components/AdminRoutes";
import Unsubscribe from "./pages/Unsubscribe";
import ProductPage from "./pages/ProductPage";
import AddProperty from "./user/AddProperty";
import MyListings from "./user/MyListings";
import EditProperty from "./user/EditProperty";
import AdminProperties from "./admin/AdminProperties";
import AdminViewProperties from "./admin/AdminViewProperties";
import AdminLeads from "./admin/AdminLeads";
import MyWishlist from "./user/MyWishlist";
import Disclaimer from "./pages/Disclaimer";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* pages */}
        <Route path="/" element={<Home />} />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/phone-skins" element={<PhoneSkins />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/search" element={<Search />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/track-order" element={<TrackOrder />} />
        <Route path="/properties/:area?" element={<ProductPage />} />
        <Route path="/property-info/:bbId?" element={<ProductInfo />} />
        <Route path="/faq" element={<FrequentlyAskedQuestions />} />
        <Route path="/unsubscribe/:email?" element={<Unsubscribe />} />
        {/* <Route path="/service" element={<Service />} /> */}
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        {/* ======================== USER PAGES =============================== */}
        {/* ========== EMAIL VERIFY */}
        <Route
          path="/add-property"
          element={
            <ProtectedRoute>
              <AddProperty />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-property/:bbId?"
          element={
            <ProtectedRoute>
              <EditProperty />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-listings"
          element={
            <ProtectedRoute>
              <MyListings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-wishlist"
          element={
            <ProtectedRoute>
              <MyWishlist />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-order/:orderId?"
          element={
            <ProtectedRoute>
              <ViewOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {/* ======================== USER PAGES =============================== */}
        {/* ======================== ADMIN PAGES =============================== */}
        <Route
          path="/admin-login"
          element={
            <PublicRoute>
              <AdminLogin />
            </PublicRoute>
          }
        />
        <Route
          path="/admin-dashboard"
          element={
            <AdminRoutes>
              <AdminDashboard />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <AdminRoutes>
              <AdminOrder />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-view-order/:orderId?"
          element={
            <AdminRoutes>
              <AdminViewOrder />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-properties"
          element={
            <AdminRoutes>
              <AdminProperties />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-view-property/:bbId?"
          element={
            <AdminRoutes>
              <AdminViewProperties />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-test"
          element={
            <ProtectedRoute>
              <PhotoUpload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-edit-user/:id?"
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-payments"
          element={
            <ProtectedRoute>
              <AdminPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-bulk-email"
          element={
            <ProtectedRoute>
              <AdminBulkEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-queries"
          element={
            <ProtectedRoute>
              <AdminQueries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-subscribers"
          element={
            <ProtectedRoute>
              <Subscribers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-leads"
          element={
            <ProtectedRoute>
              <AdminLeads />
            </ProtectedRoute>
          }
        />
        {/* ======================== ADMIN PAGES =============================== */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
