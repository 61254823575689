import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import "./EditUser.css";
import EditUserForm from "./components/EditUser/EditUserForm";
import IMAGES from "../img/image";

const EditUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/admin/admin-edit-user", user);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/admin-users");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // get user
  const getUser = async () => {
    try {
      const res = await axios.post("/api/admin/get-user", { id: params.id });
      if (res.data.success) {
        setUser(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <form>
          <div className="page-title">
            <h3 className="m-0">Edit User</h3>
            <button onClick={handleSubmit}>Update</button>
          </div>
          <div className="admin-edit-container">
            <div className="edit-admin-user-img">
              <img
                src={
                  user?.images?.length > 0
                    ? user?.images[0]
                    : user?.gender === "Male"
                    ? IMAGES.male
                    : IMAGES.female
                }
                alt=""
              />
            </div>
            <div className="edit-admin-user-form">
              <EditUserForm handleChange={handleChange} user={user && user} />
            </div>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default EditUser;
