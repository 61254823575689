import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import "./AddProperty.css";
import InfoIcon from "@mui/icons-material/Info";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { message } from "antd";
import axios from "axios";
import bhiwandiAreas from "../bhiwandiAreas";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import imageCompression from "browser-image-compression";

const EditProperty = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(form?.images || []);

  const getProperty = async (e) => {
    try {
      const res = await axios.post("/api/property/get-property", {
        bbId: params.bbId,
        address: true,
      });
      if (res.data.success) {
        setForm(res.data.data);
        setSelectedFiles(res.data.data.images);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProperty();
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
    if (name === "area") {
      const isValidArea = bhiwandiAreas?.data?.some(
        (item) => item.name === value
      );
      if (isValidArea) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const handleFileChange = async (e) => {
    const files = fileInputRef.current.files;
    if (!files) {
      // Handle the case when files are not defined
      return;
    }
    if (files.length > 8) {
      alert("You can only upload up to 8 files.");
      e.target.value = null;
      return;
    }
    // Compress each selected image before updating the state
    const compressedFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          return compressedFile;
        } catch (error) {
          console.error("Error compressing image:", error);
          return file; // If compression fails, use the original file
        }
      })
    );
    // Update selectedFiles state with the compressed files
    setSelectedFiles([...selectedFiles, ...compressedFiles]);
  };

  const handleDeleteAllPropertyImages = async (id) => {
    try {
      const res = await axios.post(
        "/api/property/delete-property-images",
        {
          id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getProperty();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    // Append form data
    for (const key in form) {
      formData.append(key, form[key]);
    }
    const filesArray = Array.from(selectedFiles);
    const isAllFilesValid = filesArray.every(
      (file) => file.size <= 1 * 1024 * 1024
    );
    // if (!isAllFilesValid) {
    //   message.error("One or more selected files exceed the 1MB size limit.");
    //   return;
    // }
    if (selectedFiles.length === 0) {
      message.error("No photo is selected");
      return;
    }
    // Append images
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("images", selectedFiles[i]);
    }
    try {
      const res = await axios.post("/api/property/update-property", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        // getProperty();
        // fileInputRef.current.value = null;
        navigate("/my-listings");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">EDIT PROPERTY</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div className="basic-info-property">
            <small className="text-danger">
              Note: This Property will go under verification process if you make
              any changes!!{" "}
            </small>
          </div>
          <div className="basic-info-property">
            <div>
              <CollectionsIcon className="me-1" />
              Property Images
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <label htmlFor="" className="form-lable mb-1">
                  <small>You can upload upto 8 images</small>
                </label>
                <input
                  aria-label="Select Image"
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  name="image"
                  required
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="form-control"
                />
                <small className="text-danger">
                  Note: Images size should be less than 1 MB
                </small>
                <div className="thumb-img">
                  {(form?.images || []).map((item, index) => (
                    <img key={index} src={item} alt={`img-${index}`} />
                  ))}
                </div>

                <button
                  onClick={() => handleDeleteAllPropertyImages(form?._id)}
                  className="bg-danger bb-btn mt-3"
                >
                  Delete All Images
                </button>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <InfoIcon className="me-1" />
              Basic Information
            </div>
            <hr />
            <div className="row">
              <div className="col-12 form-fields mb-2">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  value={form?.title}
                  onChange={handleChange}
                  placeholder="Enter property title"
                />
              </div>
              <div className="col-12 form-fields mb-2">
                <textarea
                  name="about"
                  cols="30"
                  rows="5"
                  placeholder="Write something about your property"
                  className="form-control"
                  value={form?.about}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Type</small>
                  </label>
                  <select
                    value={form?.type}
                    onChange={handleChange}
                    name="type"
                    className="form-select"
                  >
                    <option value="">Select Type</option>
                    <option value="Sell">Sell</option>
                    <option value="Rent">Rent</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Category</small>
                  </label>
                  <select
                    name="category"
                    className="form-select"
                    value={form?.category}
                    onChange={handleChange}
                  >
                    <option value="">Select Category</option>
                    <option value="House">House</option>
                    <option value="Shop/Office">Shop/Office</option>
                    <option value="Plot/Land">Plot/Land</option>
                    {/* <option value="Villa">Villa</option> */}
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    {form?.type === "Rent" ? "Monthly Rent" : "Price"}
                  </label>
                  <input
                    name="price"
                    placeholder="Enter price"
                    type="text"
                    className="form-control"
                    value={form?.price}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <EditLocationAltIcon className="me-1" />
              Location / Contacts
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Area</small>
                  </label>
                  <input
                    type="text"
                    list="areas"
                    name="area"
                    id="area"
                    value={form?.area}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Select Area"
                  />
                  <datalist id="areas" placeholder="">
                    {bhiwandiAreas?.data?.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </datalist>
                  <span className="text-danger">
                    <small>{error && "Select from the list only"}</small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-8">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Address</small>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter full address"
                    className="form-control"
                    value={form?.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <HomeWorkIcon className="me-1" />
              Property Details
            </div>
            <hr />
            <div className="row">
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Bedrooms</small>
                        </label>
                        <select
                          name="bedroom"
                          className="form-select"
                          value={form?.bedroom}
                          onChange={handleChange}
                        >
                          <option value="">Select Bedrooms</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Bathrooms</small>
                        </label>
                        <select
                          name="bathroom"
                          className="form-select"
                          value={form?.bathroom}
                          onChange={handleChange}
                        >
                          <option value="">Select Bathrooms</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              {form?.category !== "Plot/Land" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Furnishing</small>
                    </label>
                    <select
                      name="furnishing"
                      className="form-select"
                      value={form?.furnishing}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="Furnished">Furnished</option>
                      <option value="Non Furnish">Non Furnish</option>
                    </select>
                  </div>
                </div>
              )}
              {/* PROPERTY STATUS  */}
              {form?.type === "Sell" && form?.category === "Shop/Office" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Property Status</small>
                    </label>
                    <select
                      name="pStatus"
                      className="form-select"
                      value={form?.pStatus}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="New Launch">New Launch</option>
                      <option value="Ready to Move">Ready to Move</option>
                      <option value="Under Construction">
                        Under Construction
                      </option>
                    </select>
                  </div>
                </div>
              )}
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Property Status</small>
                      </label>
                      <select
                        name="pStatus"
                        className="form-select"
                        value={form?.pStatus}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="New Launch">New Launch</option>
                        <option value="Ready to Move">Ready to Move</option>
                        <option value="Under Construction">
                          Under Construction
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              {/* POSTED BY  */}
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Posted By</small>
                  </label>
                  <select
                    name="postedBy"
                    className="form-select"
                    value={form?.postedBy}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Builder">Builder</option>
                    <option value="Dealer">Dealer</option>
                    <option value="Owner">Owner</option>
                  </select>
                </div>
              </div>
              {/* BUILT UP AREA AND CARPET AREA  */}
              {form?.category !== "Plot/Land" && (
                <>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Built Up Area (ft²)</small>
                      </label>
                      <input
                        type="text"
                        name="builtupArea"
                        className="form-control"
                        placeholder="Enter in sqft"
                        value={form?.builtupArea}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Carpet Area (ft²)</small>
                      </label>
                      <input
                        type="text"
                        name="carpetArea"
                        className="form-control"
                        placeholder="Enter in sqft"
                        value={form?.carpetArea}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* MAINTENANCE COST  */}
              {form?.category !== "Plot/Land" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Maintenance Cost</small>
                    </label>
                    <input
                      type="text"
                      name="maintenanceCost"
                      className="form-control"
                      placeholder="Enter maintenance cost"
                      value={form?.maintenanceCost}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
              {/* TOTAL FLOOR  */}
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Total Floors</small>
                      </label>
                      <input
                        type="text"
                        name="totalFloors"
                        className="form-control"
                        placeholder="Enter total floors"
                        value={form?.totalFloors}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
              {/* FLOOR NO */}
              {form?.category !== "Shop/Office" &&
                form?.category !== "Plot/Land" && (
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Floor No</small>
                      </label>
                      <input
                        type="text"
                        name="floorNumber"
                        className="form-control"
                        placeholder="House floor number"
                        value={form?.floorNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
              {/* PARKING  */}
              {form?.category !== "Plot/Land" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Parking</small>
                    </label>
                    <select
                      name="parking"
                      className="form-select"
                      value={form?.parking}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="3+">3+</option>
                    </select>
                  </div>
                </div>
              )}
              {/* WASHROOM  */}
              {form?.category === "Shop/Office" && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Washroom</small>
                    </label>
                    <select
                      name="washroom"
                      className="form-select"
                      value={form?.washroom}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
              )}
              {form?.category === "Plot/Land" && (
                <>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Plot Area</small>
                      </label>
                      <div className="parent-input">
                        <input
                          type="text"
                          name="plotArea"
                          className="form-control"
                          placeholder="Enter plot area"
                          value={form?.plotArea}
                          onChange={handleChange}
                        />
                        <select
                          className="plotAreaUnit"
                          name="plotAreaUnit"
                          onChange={handleChange}
                          value={form?.plotAreaUnit}
                        >
                          <option value="sqft">sqft</option>
                          <option value="sq.yards">sq.yards</option>
                          <option value="sq.m">sq.m</option>
                          <option value="acres">acres</option>
                          <option value="guntah">gutah</option>
                          <option value="marla">marla</option>
                          <option value="cent">cent</option>
                          <option value="bigah">bigah</option>
                          <option value="ground">ground</option>
                          <option value="biswa">biswa</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Length</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="plotLength"
                        onChange={handleChange}
                        value={form?.plotLength}
                        placeholder={`Length in ${form?.plotAreaUnit}`}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Breadth</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="plotBreadth"
                        onChange={handleChange}
                        value={form?.plotBreadth}
                        placeholder={`Breadth in ${form?.plotAreaUnit}`}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>No. of open sides</small>
                      </label>
                      <select
                        name="plotOpenSides"
                        className="form-select"
                        value={form?.plotOpenSides}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="3+">3+</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-3">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Any Construction done on this property?</small>
                      </label>
                      <select
                        name="plotConsDone"
                        className="form-select"
                        value={form?.plotConsDone}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
            <button
              disabled={error}
              onClick={handleSubmit}
              className="bb-btn w-100 mt-3"
            >
              Update Property
            </button>
          </div>
          <div className="contact-us">
            <span>
              Facing Problem while posting a property? Contact our team support{" "}
              <Link
                target="_blank"
                className="text-decoration-underline"
                to="https://wa.me/919284263003"
              >
                Click Here
              </Link>
            </span>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default EditProperty;
