const IMAGES = {
  // LOGO
  noresult: require("../img/noresult.jpg"),
  logo: require("../img/logo.png"),
  //Home
  herobg: require("../img/home/herobg.jpg"),
  herobg2: require("../img/home/herobg2.webp"),
  map: require("../img/home/city-bg.png"),
  city: require("../img/home/blog5.webp"),

  // agent
  agent1: require("../img/agents/1.jpg"),
  agent2: require("../img/agents/2.jpg"),
  agent3: require("../img/agents/3.jpg"),
  agent4: require("../img/agents/4.jpg"),

  //admin
  alogin: require("../img/admin/alogin.webp"),
  camera: require("../img/admin/camera.png"),
  camera1: require("../img/admin/camera1.png"),
  camera2: require("../img/admin/camera2.png"),

  // razorpay logo
  mobi: require("../img/mobi.jpeg"),

  //HOME
  banner: require("../img/home/banner.webp"),
  banner2: require("../img/home/banner2.webp"),
  mb1: require("../img/home/mobile.webp"),
  mb2: require("../img/home/mobile2.webp"),
  mb3: require("../img/home/mobile3.webp"),
  // before: require("../img/home/before.png"),
  // after: require("../img/home/after.png"),
  before: require("../img/home/before.webp"),
  after: require("../img/home/after.webp"),

  // Products
  p1: require("../img/products/1.webp"),
  p2: require("../img/products/2.webp"),
  safe: require("../img/products/safe.png"),
  o1: require("../img/products/o1.jpg"),
  o2: require("../img/products/o2.webp"),
  o3: require("../img/products/o3.webp"),
  proimg: require("../img/products/3.jpg"),

  // Gallery
  desktop: require("../img/home/desktop.webp"),
  phone: require("../img/home/phone.webp"),

  // ABOUT
  m1: require("../img/about/m1.png"),
  m2: require("../img/about/m2.png"),
  m3: require("../img/about/m4.webp"),
  m4: require("../img/about/m4.png"),
  /// PHONE SKIN
  phoneskinbanner: require("../img/home/pskin.webp"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
};

export default IMAGES;
