import React from "react";
import "./LearnVideo.css";

const LearnVideo = () => {
  return (
    <div className="learn-video-container">
      <span className="text-center d-block m-auto">
        <span className="text-green">
          <small>
            <b>Post a Property</b>
          </small>
        </span>
        <h4 className="mb-4">How to Post a Property?</h4>
      </span>
      <iframe
        src="https://www.youtube.com/embed/ietmKP699Kg?si=x9eQgdT5PYmObfN0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default LearnVideo;
