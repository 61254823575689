import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const RefundPolicy = () => {
  return (
    <Layout>
      <section className="bg-heading">
        <h4>
          <b>Refund Policy</b>
        </h4>
      </section>
      <section className="terms-container">
        <h5>Return & Refund Policies</h5>
        <p>
          We maintain a less than 1% defect rate. If there is an issue with your
          order, get in touch with us and provide a photo showing the problem.
        </p>
        <p>
          We will reproduce any defective items after verifying the issue. Since
          each product is freshly made for you, we do not allow returns for
          reasons unrelated to quality.
        </p>
        <p>
          For instance, we cannot refund or reproduce your order if you decide
          you do not want it or prefer a different product. The key aspects of
          our returns & refund policies include:
        </p>
        <p>
          We must be contacted within 72 hours of the delivery of any defects.
        </p>
        <p>
          We require you to submit a photograph that clearly indicates the
          problem.
        </p>
        <p>
          We will pay for shipping and provide a shipping label if a return is
          required.
        </p>
        <p>
          We will reproduce or refund your order within 5 days if we verify that
          we made an error.
        </p>
        <p>
          We will use the original production time and shipping method when
          reproducing an order.
        </p>
        <p>All sales are considered final.</p>

        <h5 className="mt-5">Ensuring 100% Satisfaction</h5>
        <p>
          If there's a quality problem with your order, reach out to us, and we
          will replace any defective units at no cost.
        </p>

        <h5 className="mt-5">
          Can I Return My Order Due to Personal Preference?
        </h5>
        <p>
          If you encounter any issues with your order, contact us with a photo
          illustrating the problem, and we will address it accordingly.
        </p>
        <p>
          Quality issues are rare, but they happen on occasion, and we strive to
          address them as fast as possible. Since each product is freshly made
          for you, we do not allow returns for reasons unrelated to quality.
        </p>
        <p>
          For instance, we cannot refund or reproduce your order if you decide
          you do not want it or prefer a different product.
        </p>
        <p>
          Questions about the shipping policy should be sent to us at
          [contact@mobieheaven.com]
        </p>
      </section>
    </Layout>
  );
};

export default RefundPolicy;
