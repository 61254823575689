import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useParams } from "react-router-dom";
import "./AdminViewOrder.css";
import axios from "axios";
import { message } from "antd";
import EditIcon from "@mui/icons-material/Edit";

const AdminViewOrder = () => {
  const params = useParams();
  const [singleOrder, setSingleOrder] = useState(null);
  const [editAddress, setEditAddress] = useState(false);
  const [location, setLocation] = useState({});
  const [status, setStatus] = useState(null);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "/api/admin/update-order",
        {
          status,
          location,
          orderId: singleOrder?.orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setEditAddress(false);
        getOrderById();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setLocation({ ...location, [e.target.name]: e.target.value });
  };

  const getOrderById = async () => {
    try {
      const res = await axios.post(
        "/api/order/get-order-by-id",
        {
          orderId: params?.orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setSingleOrder(res.data.data);
        setLocation(res.data.data.address);
        setStatus(res.data.data.status);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Order Details</h3>
          <br />
        </div>
        <div className="admin-view-order-container">
          <div className="admin-order-details-container">
            <h5 className="m-0">Order #{params?.orderId}</h5>
            <span className="text-muted">Payment via Cash on delivery.</span>
            <div className="admin-order-details">
              <div className="admin-order-general-details">
                <span className="mb-3">
                  <b>General</b>
                </span>
                <br />
                <span>
                  {singleOrder?.address?.fname} {singleOrder?.address?.fname}
                </span>
                <br />
                <span>
                  {new Date(singleOrder?.createdAt).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <br />
                <span>{singleOrder?.email}</span>
                <br />
                <span>{singleOrder?.address?.mobile}</span>
              </div>
              <div className="admin-order-shipping-details">
                <span className="mb-3">
                  <b>Address</b>{" "}
                  <EditIcon onClick={() => setEditAddress(true)} />
                </span>
                <br />
                {editAddress ? (
                  <>
                    <div className="form-fields mb-2">
                      <input
                        value={location?.saddress}
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="street address"
                        name="saddress"
                        type="text"
                      />
                    </div>
                    <div className="form-fields mb-2">
                      <input
                        value={location?.baddress}
                        onChange={handleChange}
                        style={{ padding: "8px" }}
                        className="form-control"
                        placeholder="Appartment, suite, unit, etc"
                        name="baddress"
                        type="text"
                      />
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-fields mb-2">
                          <input
                            value={location?.city}
                            onChange={handleChange}
                            style={{ padding: "8px" }}
                            className="form-control"
                            placeholder="City"
                            name="city"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-fields mb-2">
                          <input
                            value={location?.state}
                            onChange={handleChange}
                            style={{ padding: "8px" }}
                            className="form-control"
                            placeholder="State"
                            name="state"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-fields mb-2">
                          <input
                            value={location?.pincode}
                            onChange={handleChange}
                            style={{ padding: "8px" }}
                            className="form-control"
                            placeholder="Pin code"
                            name="pincode"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span>
                    Street Address: {singleOrder?.address?.saddress},
                    <br />
                    Appartment: {singleOrder?.address?.baddress},
                    <br />
                    City: {singleOrder?.address?.city},
                    <br />
                    State: {singleOrder?.address?.state},
                    <br />
                    PinCode: {singleOrder?.address?.pincode}
                  </span>
                )}
              </div>
            </div>
            <div className="admin-order-item-details">
              <h5 className="m-0 mb-3">Order Item</h5>
              <div className="admin-order-item">
                <div className="item-img">Image</div>
                <div className="item-brand">
                  <span>Brand/Model</span>
                </div>
                <div className="item-cost">Price</div>
                <div className="item-qty">Qty</div>
              </div>
              {singleOrder?.order?.map((item, index) => {
                return (
                  <div key={index} className="admin-order-item">
                    <div className="item-img">
                      <img src={item?.images[0]} alt="" />
                    </div>
                    <div className="item-brand">
                      <span className="text-decoration-underline">
                        <small>{item?.name}</small>
                      </span>
                      <br />
                      <span>
                        <small>Brand: {item?.selectedBrand}</small>
                      </span>
                      <br />
                      <span>
                        <small>Model: {item?.selectedModel}</small>
                      </span>
                    </div>
                    <div className="item-cost">
                      <b>{item?.price}</b>
                    </div>
                    <div className="item-qty">{item?.quantity}</div>
                  </div>
                );
              })}
            </div>
            <div className="admin-order-total">
              <p className="mb-1">
                Subtotal: <b>₹{parseInt(singleOrder?.subTotal)}</b>
              </p>
              <p className="m-0">
                Shipping: <b>₹{singleOrder?.shipping}</b>
              </p>
              <p className="m-0">
                Discount: <b>₹{singleOrder?.discount || 0}</b>
              </p>
              <hr />
              <p className="m-0">
                Order Total: <b> ₹{parseInt(singleOrder?.total)}</b>
              </p>
            </div>
          </div>
          {/* ====================== ACTION ===================== */}
          <div className="admin-order-actions">
            <div className="form-fields">
              <select
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                name="status"
                className="form-select"
              >
                <option value="pending payment">Pending Payment</option>
                <option value="processing">Processing</option>
                <option value="shipped">Shipped</option>
                <option value="on hold">On Hold</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
                <option value="refunded">Redunded</option>
                <option value="failed">Failed</option>
              </select>
              <button className="add-to-cart-btn w-100" onClick={handleUpdate}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminViewOrder;
