import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HomeWorkIcon from "@mui/icons-material/HomeWork";

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [dbMenu, setDbMenu] = useState(false);
  const [dbBackdrop, setDbBackdrop] = useState(false);

  const handleLogout = () => {
    localStorage.clear("token");
    navigate("/login");
  };

  return (
    <div className="dashboard-container">
      <div
        className="mobile-menu d-block d-md-block d-lg-none"
        onClick={() => {
          setDbBackdrop(!dbBackdrop);
          {
            setDbMenu(!dbMenu);
            setDbBackdrop(!dbBackdrop);
          }
        }}
      >
        <DashboardIcon className="icon" />
        <span>Dashboard Menu</span>
      </div>
      <div
        className={`db-menu-backdrop ${dbBackdrop && "active"}`}
        onClick={() => {
          setDbMenu(false);
          setDbBackdrop(false);
        }}
      ></div>
      <div
        className={`db-menu d-block d-md-block d-lg-none d-flex ${
          dbMenu && "active"
        }`}
      >
        <h6>
          <small>MAIN</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/user-dashboard" && "active"}`}
          >
            <Link to="/user-dashboard">
              <DashboardIcon className="me-1" /> Dashboard
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/my-account">
              <AccountCircleIcon className="me-1" />
              My Account
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>LISTINGS</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/my-listings">
              <FormatListNumberedRtlIcon className="me-1" />
              My Listings
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/my-wishlist">
              <FavoriteIcon className="me-1" />
              My Wishlist
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/add-property">
              <AddBusinessIcon className="me-1" />
              Add New
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>PROPERTIES</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/properties">
              <HomeWorkIcon className="me-1" />
              Properties
            </Link>
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <LogoutIcon className="me-1" />
            Logout
          </li>
        </ul>
      </div>

      {/* DASHBOARD VIEW  */}
      <div className="dashboard-menu d-none d-md-none d-lg-block">
        <h6>
          <small>MAIN</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/user-dashboard" && "active"}`}
          >
            <Link to="/user-dashboard">
              <DashboardIcon className="me-1" /> Dashboard
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/my-account">
              <AccountCircleIcon className="me-1" />
              My Account
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>LISTINGS</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/my-listings">
              <FormatListNumberedRtlIcon className="me-1" />
              My Listings
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/my-wishlist">
              <FavoriteIcon className="me-1" />
              My Wishlist
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/add-property">
              <AddBusinessIcon className="me-1" />
              Add New
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>LISTINGS</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/properties">
              <HomeWorkIcon className="me-1" />
              Properties
            </Link>
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <LogoutIcon className="me-1" />
            Logout
          </li>
        </ul>
      </div>
      <div className="dashboard-content">{children}</div>
    </div>
  );
};

export default DashboardLayout;
