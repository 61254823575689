import React from "react";
import "./NoResultFound.css";
import IMAGES from "../../img/image";

const NoResultFound = () => {
  return (
    <div className="no-result-found">
      <img src={IMAGES.noresult} alt="" />
      <h5>No Result Found</h5>
    </div>
  );
};

export default NoResultFound;
