import React from "react";
import IMAGES from "../../img/image";
import trusted from "../../img/home/trusted.svg";
import register from "../../img/home/register.svg";
import privacy from "../../img/home/privacy.svg";
import verified from "../../img/home/verified.svg";
import "./HowItWorks.css";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LanguageIcon from "@mui/icons-material/Language";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const HowItWorks = () => {
  return (
    <div
      className="container-fluid how-it-works area"
      style={{ borderTopLeftRadius: "3px", borderTopRightRadius: "3px" }}
    >
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container before-footer">
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-5 mb-md-0 mb-lg-0">
            <h2 className="m-0">50+</h2>
            <span>
              <small>Agents and Agencies</small>
            </span>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-5 mb-md-0 mb-lg-0">
            <h2 className="m-0">2k+</h2>
            <span>
              <small>Happy Customers</small>
            </span>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-5 mb-md-0 mb-lg-0">
            <h2 className="m-0">5k+</h2>
            <span>
              <small>Properties</small>
            </span>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-0 mb-md-0 mb-lg-0">
            <h2 className="m-0">100+</h2>
            <span>
              <small>New Listing Every Week</small>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
