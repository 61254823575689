import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./Unsubscribe.css";
import { message } from "antd";
import axios from "axios";

const Unsubscribe = () => {
  const params = useParams();
  const navigate = useNavigate();

  const handleUnsubscribe = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/unsubscribe", {
        email: params?.email,
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="unsubscribe-container">
      <h3>Are you sure you want to unsubscribe?</h3>
      <p>You will miss latest updates..</p>
      <div>
        <button onClick={handleUnsubscribe}>Unsubscribe</button>
        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    </div>
  );
};

export default Unsubscribe;
