import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AdminUsers.css";

const AdminProperties = () => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState(null);
  const [loading, setLoading] = useState(false);

  // delete product
  const handleDeleteProduct = async (id, images) => {
    const shouldDelete = window.confirm("Are you sure to delete?");
    if (shouldDelete) {
      try {
        const res = await axios.post("/api/product/delete-product", {
          id,
          images,
        });
        if (res.data.success) {
          getAllProperties();
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      // User clicked "Cancel" or closed the dialog
    }
  };

  // get all products
  const getAllProperties = async () => {
    try {
      const res = await axios.get("/api/admin/admin-get-all-properties", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setProperties(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProperties();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Properties</h3>
          <button onClick={() => navigate("/admin-add-product")}>
            Add New
          </button>
        </div>
        <hr />
        <div className="table-container">
          {/* <div className="tools">
            <div>
              <SearchIcon className="text-dark me-2" />
              <input
                className="mb-4"
                type="search"
                name="search"
                placeholder="Search by name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div> */}
          <table className="table user-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Type</th>
                <th>Category</th>
                <th>Price</th>
                <th>Area</th>
                <th>Verified</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {properties &&
                properties?.map((property, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img src={property?.images[0]} alt="" />
                      </td>
                      <td>
                        <small>{property?.title}</small>
                      </td>
                      <td>
                        <small>{property?.type}</small>
                      </td>
                      <td>
                        <small>{property?.category}</small>
                      </td>
                      <td>
                        <small>{property?.price}</small>
                      </td>
                      <td>
                        <small>{property?.area}</small>
                      </td>
                      <td>
                        <small>{property?.verified ? "Yes" : "No"}</small>
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <EditIcon
                            onClick={() =>
                              navigate(`/admin-view-property/${property?.bbId}`)
                            }
                            className="me-2 text-muted"
                          />
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleDeleteProduct(
                                property?._id,
                                property?.images
                              )
                            }
                            className="text-danger"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="pagination"></div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminProperties;
