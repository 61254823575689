import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const AdminLeads = () => {
  const navigate = useNavigate();
  const [leads, setLeads] = useState(null);

  async function getAllLeads() {
    try {
      const res = await axios.get("/api/admin/get-leads", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLeads(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllLeads();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Leads</h3>
        </div>
        <hr />
        <div className="table-container">
          <table className="table user-table">
            <thead>
              <tr>
                <th>BB ID</th>
                <th>Seller Email</th>
                <th>Seller Mobile</th>
                <th>Buyer Name</th>
                <th>Buyer Email</th>
                <th>Buyer Mobile</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {leads &&
                leads.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <small>{item?.bbId}</small>
                      </td>
                      <td>
                        <small>{item?.sellerEmail}</small>
                      </td>
                      <td>
                        <small>{item?.sellerMobile}</small>
                      </td>
                      <td>
                        <small>{item?.name}</small>
                      </td>
                      <td>
                        <small>{item?.email}</small>
                      </td>
                      <td>
                        <small>{item?.mobile}</small>
                      </td>
                      <td>
                        <RemoveRedEyeIcon
                          className="icon"
                          onClick={() =>
                            navigate(`/admin-view-property/${item?.bbId}`)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="pagination"></div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminLeads;
